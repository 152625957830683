import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Loader2, RefreshCw } from 'lucide-react';
import { authenticatedApiCall } from '../utils/authenticatedApiCall';
import ContentPreview from './ContentPreview';
import ContentCardButtons from './ContentCardButtons';
import { useNavigate } from 'react-router-dom';
import '../css/ContentViewer.css';

const CACHE_DURATION = 30 * 60 * 1000;

const ContentViewer = () => {
    const navigate = useNavigate();
    const [viewMode, setViewMode] = useState('All');
    const [error, setError] = useState(null);
    const [selectedContent, setSelectedContent] = useState(null);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [content, setContent] = useState([]);
    const [sortBy, setSortBy] = useState('latest');
    const [loading, setLoading] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const lastFetchTime = useRef(null);
    const previousViewMode = useRef('All');

    const toolName = 'Content Generator';
    const isLoggedIn = localStorage.getItem('token') !== null;
    const currentUser = isLoggedIn ? JSON.parse(localStorage.getItem('user') || '{}') : null;

    // Basic utility functions that don't need to change often
    const getCacheKey = useCallback((mode) => {
        return `contentCache_${mode}_${sortBy}`;
    }, [sortBy]);

    const sortContent = useCallback((data) => {
        if (sortBy === 'votes') {
            return [...data].sort((a, b) => {
                if (b.vote_count !== a.vote_count) {
                    return b.vote_count - a.vote_count;
                }
                return new Date(b.created_at.$date) - new Date(a.created_at.$date);
            });
        }
        return [...data].sort((a, b) =>
            new Date(b.created_at.$date) - new Date(a.created_at.$date)
        );
    }, [sortBy]);

    // Cache management functions
    const saveToCache = useCallback((contentData, mode, pageNum, hasMoreItems) => {
        const cacheData = {
            content: contentData,
            page: pageNum,
            hasMore: hasMoreItems,
            timestamp: Date.now(),
            sortBy
        };
        localStorage.setItem(getCacheKey(mode), JSON.stringify(cacheData));
    }, [getCacheKey, sortBy]);

    const loadFromCache = useCallback((mode) => {
        const cachedData = localStorage.getItem(getCacheKey(mode));
        if (cachedData) {
            const { content: cachedContent, page: cachedPage, hasMore: cachedHasMore, timestamp, sortBy: cachedSortBy } = JSON.parse(cachedData);
            if (Date.now() - timestamp < CACHE_DURATION && sortBy === cachedSortBy) {
                setContent(cachedContent);
                setPage(cachedPage);
                setHasMore(cachedHasMore);
                setLoading(false);
                lastFetchTime.current = timestamp;
                return true;
            } else {
                localStorage.removeItem(getCacheKey(mode));
            }
        }
        return false;
    }, [getCacheKey, sortBy]);

    const updateCache = useCallback((mode, updatedContent) => {
        const cacheKey = getCacheKey(mode);
        const cachedData = localStorage.getItem(cacheKey);

        if (cachedData) {
            const { page: cachedPage, hasMore: cachedHasMore, timestamp } = JSON.parse(cachedData);
            const sortedContent = sortContent(updatedContent);
            const cacheData = {
                content: sortedContent,
                page: cachedPage,
                hasMore: cachedHasMore,
                timestamp,
                sortBy
            };
            localStorage.setItem(cacheKey, JSON.stringify(cacheData));
        }
    }, [getCacheKey, sortBy, sortContent]);

    // State reset function
    const resetContentState = useCallback(() => {
        setContent([]);
        setPage(1);
        setHasMore(true);
        setLoading(true);
        localStorage.removeItem(getCacheKey(viewMode));
        lastFetchTime.current = null;
    }, [getCacheKey, viewMode]);

    // Core data fetching functions
    const fetchSavedContent = useCallback(async (shouldReset = false) => {
        if (shouldReset) {
            setLoading(true);
        }
        try {
            const response = await authenticatedApiCall('/user/saved-content', 'GET');
            if (response.savedContent) {
                setContent(response.savedContent);
                setHasMore(false);
                if (shouldReset) {
                    saveToCache(response.savedContent, 'Bookmarks', 1, false);
                }
            }
        } catch (err) {
            console.error('Error fetching saved content:', err);
            setError('Error fetching saved content');
        } finally {
            setLoading(false);
        }
    }, [saveToCache]);

    const fetchAndSortContent = useCallback(async (mode = viewMode, pageNum = page, shouldReset = false) => {
        if (shouldReset) {
            setLoading(true);
        }
        try {
            const endpoint = mode === 'All' ? '/content/public' : '/content';
            const encodedToolName = encodeURIComponent(toolName);
            const response = await authenticatedApiCall(
                `${endpoint}/${encodedToolName}?page=${pageNum}&per_page=10&sort=${sortBy}`,
                'GET'
            );

            if (response.contents && Array.isArray(response.contents)) {
                const sortedContent = sortContent(response.contents);
                if (shouldReset) {
                    setContent(sortedContent);
                } else {
                    setContent(prev => [...prev, ...sortedContent]);
                }
                setHasMore(response.contents.length === 10);

                const newContent = shouldReset ? sortedContent : content.concat(sortedContent);
                saveToCache(newContent, mode, pageNum + 1, response.contents.length === 10);
            }
        } catch (err) {
            console.error('Error fetching content:', err);
            setError('Error fetching content. Please try again later.');
        } finally {
            setLoading(false);
        }
    }, [sortContent, saveToCache, sortBy, toolName, content, page, viewMode]);

    // Event handlers that use the core functions
    const handleViewChange = useCallback((newMode) => {
        const handleViewUpdate = async () => {
            resetContentState();
            if (newMode === 'Bookmarks') {
                await fetchSavedContent(true);
            } else {
                await fetchAndSortContent(newMode, 1, true);
            }
        };

        if (newMode === viewMode) {
            handleViewUpdate();
        } else {
            previousViewMode.current = viewMode;
            setViewMode(newMode);
            setPage(1);
            setContent([]);
            setLoading(true);
        }
    }, [viewMode, resetContentState, fetchSavedContent, fetchAndSortContent]);

    const handleRefresh = useCallback(() => {
        const refreshContent = async () => {
            resetContentState();
            if (viewMode === 'Bookmarks') {
                await fetchSavedContent(true);
            } else {
                await fetchAndSortContent(viewMode, 1, true);
            }
        };
        refreshContent();
    }, [viewMode, resetContentState, fetchSavedContent, fetchAndSortContent]);

    // Initial load effect
    useEffect(() => {
        const loadContent = async () => {
            if (!loadFromCache(viewMode)) {
                if (viewMode === 'Bookmarks') {
                    await fetchSavedContent();
                } else {
                    await fetchAndSortContent(viewMode, 1, true);
                }
            }
        };

        if (loading && content.length === 0) {
            loadContent();
        }
    }, [viewMode, loading, content.length, loadFromCache, fetchSavedContent, fetchAndSortContent]);

    const handleLoadMore = async () => {
        setLoadingMore(true);
        try {
            const nextPage = page + 1;
            await fetchAndSortContent(viewMode, nextPage);
            setPage(nextPage);
        } catch (err) {
            console.error('Error loading more content:', err);
            setError('Error loading more content. Please try again later.');
        } finally {
            setLoadingMore(false);
        }
    };

    // Rest of the existing methods (handleVote, handleSave, handleReport, etc.) remain the same
    const handleVote = async (id) => {
        if (!isLoggedIn) {
            handleAuthRequired();
            return;
        }
        try {
            const response = await authenticatedApiCall(`/update-vote-count/${id}`, 'POST');
            const updatedContent = content.map(item =>
                item._id.$oid === id ? {
                    ...item,
                    vote_count: response.vote_count,
                    is_voted: response.is_voted,
                    voted_by: response.is_voted
                        ? [...(item.voted_by || []), currentUser.id]
                        : (item.voted_by || []).filter(userId => userId !== currentUser.id)
                } : item
            );

            setContent(updatedContent);

            // Update cache for current view mode
            updateCache(viewMode, updatedContent);

            // Update other view modes if their caches exist
            ['All', 'Your'].forEach(mode => {
                if (mode !== viewMode) {
                    const modeCacheKey = getCacheKey(mode);
                    const modeCachedData = localStorage.getItem(modeCacheKey);

                    if (modeCachedData) {
                        const parsedData = JSON.parse(modeCachedData);
                        const updatedModeContent = parsedData.content.map(item =>
                            item._id.$oid === id ? {
                                ...item,
                                vote_count: response.vote_count,
                                is_voted: response.is_voted,
                                voted_by: response.is_voted
                                    ? [...(item.voted_by || []), currentUser.id]
                                    : (item.voted_by || []).filter(userId => userId !== currentUser.id)
                            } : item
                        );
                        updateCache(mode, updatedModeContent);
                    }
                }
            });
        } catch (err) {
            console.error('Error voting content:', err);
            setError(`Error voting content: ${err.message}`);
        }
    };

    const handleSave = async (contentItem) => {
        if (!isLoggedIn) {
            handleAuthRequired();
            return;
        }
        try {
            const response = await authenticatedApiCall('/save-others-content', 'POST', {
                content_id: contentItem._id.$oid
            });

            if (response.success) {
                const updatedContent = content.map(item =>
                    item._id.$oid === contentItem._id.$oid ? {
                        ...item,
                        is_saved: response.is_saved,
                        saved_by: response.is_saved
                            ? [...(item.saved_by || []), currentUser.id]
                            : (item.saved_by || []).filter(userId => userId !== currentUser.id)
                    } : item
                );

                setContent(updatedContent);

                // Update cache for current view mode
                updateCache(viewMode, updatedContent);

                // Update other view modes if their caches exist
                ['All', 'Your'].forEach(mode => {
                    if (mode !== viewMode) {
                        const modeCacheKey = getCacheKey(mode);
                        const modeCachedData = localStorage.getItem(modeCacheKey);

                        if (modeCachedData) {
                            const parsedData = JSON.parse(modeCachedData);
                            const updatedModeContent = parsedData.content.map(item =>
                                item._id.$oid === contentItem._id.$oid ? {
                                    ...item,
                                    is_saved: response.is_saved,
                                    saved_by: response.is_saved
                                        ? [...(item.saved_by || []), currentUser.id]
                                        : (item.saved_by || []).filter(userId => userId !== currentUser.id)
                                } : item
                            );
                            updateCache(mode, updatedModeContent);
                        }
                    }
                });

                // Handle Bookmarks view cache
                const bookmarksCacheKey = getCacheKey('Bookmarks');
                const bookmarksCachedData = localStorage.getItem(bookmarksCacheKey);

                if (bookmarksCachedData) {
                    const parsedData = JSON.parse(bookmarksCachedData);
                    let updatedBookmarksContent;

                    if (response.is_saved) {
                        // Add content to bookmarks if saved
                        const newBookmark = {
                            ...contentItem,
                            is_saved: true,
                            saved_by: [...(contentItem.saved_by || []), currentUser.id]
                        };
                        updatedBookmarksContent = [...parsedData.content, newBookmark];
                    } else {
                        // Remove content from bookmarks if unsaved
                        updatedBookmarksContent = parsedData.content.filter(
                            item => item._id.$oid !== contentItem._id.$oid
                        );
                    }

                    updateCache('Bookmarks', updatedBookmarksContent);
                }

                // If we're in Bookmarks view, refresh the content
                if (viewMode === 'Bookmarks') {
                    fetchSavedContent();
                }
            }
        } catch (err) {
            console.error('Error saving content:', err);
            setError(`Error saving content: ${err.message}`);
        }
    };

    const handleReport = async (id) => {
        if (!isLoggedIn) {
            handleAuthRequired();
            return;
        }
        try {
            await authenticatedApiCall(`/report-content/${id}`, 'POST');
            setContent(prevContent => prevContent.map(item =>
                item._id.$oid === id ? { ...item, reported: true } : item
            ));
        } catch (err) {
            console.error('Error reporting content:', err);
            setError(`Error reporting content: ${err.message}`);
        }
    };

    const handleAuthRequired = () => {
        navigate('/login');
    };

    const handleSortChange = (newSortBy) => {
        setSortBy(newSortBy);
        resetContentState();
    };

    const handleContentClick = (item, event) => {
        if (event.target.tagName === 'A' && event.target.href.includes('/profile/')) {
            return;
        }
        setSelectedContent(item);
        setIsEditing(false);
    };

    const handleClosePreview = () => {
        setSelectedContent(null);
        setIsEditing(false);
    };

    const handleEditContent = async (id, editedContent) => {
        try {
            await authenticatedApiCall(`/content/${id}`, 'PUT', { content: editedContent });
            setContent(prevContent => prevContent.map(item =>
                item._id.$oid === id ? { ...item, content: editedContent } : item
            ));
        } catch (err) {
            console.error('Error editing content:', err);
            setError(`Error editing content: ${err.message}`);
        }
    };

    const handleDeleteContent = async (id) => {
        try {
            await authenticatedApiCall(`/content/${id}`, 'DELETE');

            // Update the current view's content state
            setContent(prevContent => prevContent.filter(item => item._id.$oid !== id));

            // Update cache for all view modes
            ['All', 'Your', 'Bookmarks'].forEach(mode => {
                const cacheKey = getCacheKey(mode);
                const cachedData = localStorage.getItem(cacheKey);

                if (cachedData) {
                    const parsedData = JSON.parse(cachedData);
                    const updatedContent = parsedData.content.filter(item => item._id.$oid !== id);

                    const cacheData = {
                        content: updatedContent,
                        page: parsedData.page,
                        hasMore: parsedData.hasMore,
                        timestamp: parsedData.timestamp,
                        sortBy
                    };

                    localStorage.setItem(cacheKey, JSON.stringify(cacheData));
                }
            });

            // If we're viewing a preview, close it
            if (selectedContent && selectedContent._id.$oid === id) {
                handleClosePreview();
            }
        } catch (err) {
            console.error('Error deleting content:', err);
            setError(`Error deleting content: ${err.message}`);
        }
    };

    const SkeletonCard = () => (
        <div className="cp-content-card cp-skeleton">
            <div className="cp-skeleton-image"></div>
            <div className="cp-skeleton-text"></div>
            <div className="cp-skeleton-text cp-skeleton-text-short"></div>
        </div>
    );

    const boldAsterisks = (text) => {
        const parts = text.split(/(\*\*[^*]+\*\*)/);
        let firstBolded = false;

        return parts.map((part, index) => {
            if (part.startsWith('**') && part.endsWith('**')) {
                if (!firstBolded) {
                    firstBolded = true;
                    return (
                        <React.Fragment key={index}>
                            <strong className='cp-card-title'>{part.slice(2, -2)}</strong><br />
                        </React.Fragment>
                    );
                } else {
                    return part.slice(2, -2);
                }
            }
            return part;
        });
    };


    return (
        <div className="cp-content-viewer margin">
            <div className="cp-view-controls">
                <div className="cp-view-mode-buttons">
                    <button
                        onClick={() => !loading && handleViewChange('All')}
                        className={`cp-view-mode-button ${viewMode === 'All' ? 'cp-active' : ''}`}
                        disabled={loading}
                    >
                        All
                    </button>
                    <button
                        onClick={() => !loading && handleViewChange('Your')}
                        className={`cp-view-mode-button ${viewMode === 'Your' ? 'cp-active' : ''}`}
                        disabled={loading}
                    >
                        Your
                    </button>
                    <button
                        onClick={() => !loading && handleViewChange('Bookmarks')}
                        className={`cp-view-mode-button ${viewMode === 'Bookmarks' ? 'cp-active' : ''}`}
                        disabled={loading}
                    >
                        Bookmarks
                    </button>
                </div>
                <div className="cp-sort-controls">
                    <select
                        value={sortBy}
                        onChange={(e) => handleSortChange(e.target.value)}
                        className="cp-sort-select"
                        disabled={loading || viewMode === 'Bookmarks'}
                    >
                        <option value="votes">Most Upvoted</option>
                        <option value="latest">Latest First</option>
                    </select>
                    <button
                        onClick={handleRefresh}
                        className="cp-refresh-button"
                        disabled={loading}
                    >
                        <RefreshCw className={loading ? 'animate-spin' : ''} size={18} />
                    </button>
                </div>
            </div>
            {error && <p className="cp-error">{error}</p>}
            <div className="cp-content-grid">
                {loading && content.length === 0 ? (
                    [...Array(10)].map((_, index) => <SkeletonCard key={index} />)
                ) : (
                    content.map((item) => (
                        <div
                            key={item._id.$oid}
                            className="cp-content-card"
                            onClick={(event) => handleContentClick(item, event)}
                        >
                            {item.image_urls && item.image_urls.length > 0 && (
                                <img
                                    src={item.image_urls[0]}
                                    alt="Content preview"
                                    className="cp-card-image"
                                />
                            )}
                            <p className="cp-card-content">
                                {boldAsterisks(item.content.substring(0, 150) + '...')}
                            </p>
                            {viewMode === 'All' && (
                                <p className="cp-card-author">
                                    By: <a href={`/profile/${item.user_name}`} onClick={(e) => e.stopPropagation()}>{item.user_name || 'Anonymous'}</a>
                                </p>
                            )}
                            <p className="cp-card-date">
                                {item.content_type && (
                                   <> Content Type: {item.content_type.charAt(0).toUpperCase() + item.content_type.slice(1)} <br /> </>
                                )}
                                Created at: {new Date(item.created_at.$date).toLocaleString()} <br />
                                {item.updated_at && (
                                    <> Updated at: {new Date(item.updated_at.$date).toLocaleString()}</>
                                )}
                            </p>
                            <ContentCardButtons
                                contentId={item._id.$oid}
                                contentItem={item}
                                voteCount={item.vote_count}
                                votedBy={item.voted_by}
                                savedBy={item.saved_by}
                                reportedBy={item.reported_by}
                                isLoggedIn={isLoggedIn}
                                currentUsername={currentUser?.username}
                                currentId={currentUser?.id}
                                onVote={handleVote}
                                onSave={handleSave}
                                onReport={handleReport}
                                viewMode={viewMode}
                                onAuthRequired={handleAuthRequired}
                            />
                            {viewMode === 'Your' && (
                                <div className="cp-card-buttons">
                                    <button
                                        className="cp-edit-button"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleContentClick(item, e);
                                            setIsEditing(true);
                                        }}
                                    >
                                        Edit
                                    </button>
                                    <button
                                        className="cp-delete-button"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDeleteContent(item._id.$oid);
                                        }}
                                    >
                                        Delete
                                    </button>
                                </div>
                            )}
                        </div>
                    ))
                )}
            </div>

            {hasMore && !loading && (
                <button
                    className="cp-load-more-button"
                    onClick={handleLoadMore}
                    disabled={loadingMore}
                >
                    {loadingMore ? (
                        <>
                            <Loader2 className="animate-spin" />
                        </>
                    ) : (
                        'Load More'
                    )}
                </button>
            )}

            {loading && page > 1 && (
                <div className="cp-content-grid">
                    {[...Array(10)].map((_, index) => <SkeletonCard key={index} />)}
                </div>
            )}

            {selectedContent && (
                <ContentPreview
                    content={selectedContent}
                    onClose={handleClosePreview}
                    onEdit={handleEditContent}
                    onDelete={handleDeleteContent}
                    isYourContent={viewMode === 'Your'}
                    startEditing={isEditing}
                />
            )}
        </div>
    );
};

export default ContentViewer;