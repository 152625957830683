// components/ImageGenerator/SettingsPanel.js
import React from 'react';
import DimensionControls from './DimensionControls';

const SettingsPanel = ({ formData, handleChange, fieldOptions, dimensionPresets }) => {
    return (
        <div className="imgen-mobile-settings-view">
            <select
                name="numImages"
                value={formData.numImages}
                onChange={handleChange}
                className="imgen-settings-select"
            >
                <option value={1}>Generate 1 Image</option>
                <option value={2}>Generate 2 Images</option>
                <option value={3}>Generate 3 Images</option>
                <option value={4}>Generate 4 Images</option>
            </select>
            {['model', 'artStyle', 'perspective', 'theme', 'lighting', 'colorPalette', 'composition', 'mood'].map((field) => (
                <select
                    key={field}
                    name={field}
                    value={formData[field]}
                    onChange={handleChange}
                    className="imgen-settings-select"
                >
                    <option value="">{`Select ${field.charAt(0).toUpperCase() + field.slice(1)}:`}</option>
                    {fieldOptions[field].map(option => (
                        <option key={option} value={option}>
                            {option.charAt(0).toUpperCase() + option.slice(1)}
                        </option>
                    ))}
                </select>
            ))}
            <DimensionControls
                formData={formData}
                handleChange={handleChange}
                dimensionPresets={dimensionPresets}
            />
        </div>
    );
};

export default SettingsPanel;
