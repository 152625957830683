import { useState, useCallback } from 'react';
import { authenticatedApiCall } from '../../utils/authenticatedApiCall';

const useCommentHandlers = () => {
  const [commentStates, setCommentStates] = useState({});
  const [error, setError] = useState(null);

  const handleCreateComment = useCallback(async (content, imageId, parentId = null) => {
    if (!content || !imageId) {
      setError('Content and image ID are required');
      return { success: false, message: 'Missing required fields' };
    }

    const commentData = {
      content,
      image_id: imageId,
      ...(parentId && { parent_id: parentId })
    };

    setCommentStates(prev => ({
      ...prev,
      [imageId]: { ...prev[imageId], creating: 'loading' }
    }));

    try {
      const response = await authenticatedApiCall(
        '/comments',
        'POST',
        commentData
      );

      setCommentStates(prev => ({
        ...prev,
        [imageId]: { ...prev[imageId], creating: 'success' }
      }));

      setTimeout(() => {
        setCommentStates(prev => ({
          ...prev,
          [imageId]: { ...prev[imageId], creating: 'default' }
        }));
      }, 3000);

      return {
        success: true,
        message: 'Comment created successfully',
        comment: response
      };
    } catch (error) {
      setCommentStates(prev => ({
        ...prev,
        [imageId]: { ...prev[imageId], creating: 'default' }
      }));
      setError(error.message || 'Failed to create comment');
      return { success: false, message: error.message };
    }
  }, []);

  const handleGetComments = useCallback(async (imageId, page = 1, perPage = 10, parentOnly = true) => {
    setCommentStates(prev => ({
      ...prev,
      [imageId]: { ...prev[imageId], loading: 'loading' }
    }));

    try {
      const response = await authenticatedApiCall(
        `/images/${imageId}/comments?page=${page}&per_page=${perPage}&parent_only=${parentOnly}`,
        'GET'
      );

      setCommentStates(prev => ({
        ...prev,
        [imageId]: { ...prev[imageId], loading: 'success' }
      }));

      return {
        success: true,
        ...response
      };
    } catch (error) {
      setCommentStates(prev => ({
        ...prev,
        [imageId]: { ...prev[imageId], loading: 'default' }
      }));
      setError(error.message || 'Failed to fetch comments');
      return { success: false, message: error.message };
    }
  }, []);

  const handleGetReplies = useCallback(async (commentId, page = 1, perPage = 10) => {
    setCommentStates(prev => ({
      ...prev,
      [commentId]: { ...prev[commentId], loadingReplies: 'loading' }
    }));

    try {
      const response = await authenticatedApiCall(
        `/comments/${commentId}/replies?page=${page}&per_page=${perPage}`,
        'GET'
      );

      setCommentStates(prev => ({
        ...prev,
        [commentId]: { ...prev[commentId], loadingReplies: 'success' }
      }));

      return {
        success: true,
        ...response
      };
    } catch (error) {
      setCommentStates(prev => ({
        ...prev,
        [commentId]: { ...prev[commentId], loadingReplies: 'default' }
      }));
      setError(error.message || 'Failed to fetch replies');
      return { success: false, message: error.message };
    }
  }, []);

  const handleUpdateComment = useCallback(async (commentId, content) => {
    if (!content) {
      setError('Content is required');
      return { success: false, message: 'Content is required' };
    }

    setCommentStates(prev => ({
      ...prev,
      [commentId]: { ...prev[commentId], updating: 'loading' }
    }));

    try {
      const response = await authenticatedApiCall(
        `/comments/${commentId}`,
        'PUT',
        { content }
      );

      setCommentStates(prev => ({
        ...prev,
        [commentId]: { ...prev[commentId], updating: 'success' }
      }));

      setTimeout(() => {
        setCommentStates(prev => ({
          ...prev,
          [commentId]: { ...prev[commentId], updating: 'default' }
        }));
      }, 3000);

      return {
        success: true,
        message: 'Comment updated successfully',
        comment: response
      };
    } catch (error) {
      setCommentStates(prev => ({
        ...prev,
        [commentId]: { ...prev[commentId], updating: 'default' }
      }));
      setError(error.message || 'Failed to update comment');
      return { success: false, message: error.message };
    }
  }, []);

  const handleDeleteComment = useCallback(async (commentId) => {
    setCommentStates(prev => ({
      ...prev,
      [commentId]: { ...prev[commentId], deleting: 'loading' }
    }));

    try {
      await authenticatedApiCall(
        `/comments/${commentId}`,
        'DELETE'
      );

      setCommentStates(prev => ({
        ...prev,
        [commentId]: { ...prev[commentId], deleting: 'success' }
      }));

      setTimeout(() => {
        setCommentStates(prev => ({
          ...prev,
          [commentId]: { ...prev[commentId], deleting: 'default' }
        }));
      }, 3000);

      return {
        success: true,
        message: 'Comment deleted successfully'
      };
    } catch (error) {
      setCommentStates(prev => ({
        ...prev,
        [commentId]: { ...prev[commentId], deleting: 'default' }
      }));
      setError(error.message || 'Failed to delete comment');
      return { success: false, message: error.message };
    }
  }, []);

  const handleToggleLike = useCallback(async (commentId) => {
    setCommentStates(prev => ({
      ...prev,
      [commentId]: { ...prev[commentId], liking: 'loading' }
    }));

    try {
      const response = await authenticatedApiCall(
        `/comments/${commentId}/like`,
        'POST'
      );

      setCommentStates(prev => ({
        ...prev,
        [commentId]: { ...prev[commentId], liking: 'success' }
      }));

      setTimeout(() => {
        setCommentStates(prev => ({
          ...prev,
          [commentId]: { ...prev[commentId], liking: 'default' }
        }));
      }, 3000);

      return {
        success: true,
        message: response.message,
        comment: response.comment
      };
    } catch (error) {
      setCommentStates(prev => ({
        ...prev,
        [commentId]: { ...prev[commentId], liking: 'default' }
      }));
      setError(error.message || 'Failed to toggle like');
      return { success: false, message: error.message };
    }
  }, []);

  const clearError = useCallback(() => {
    setError(null);
  }, []);

  return {
    commentStates,
    error,
    handleCreateComment,
    handleGetComments,
    handleGetReplies,
    handleUpdateComment,
    handleDeleteComment,
    handleToggleLike,
    clearError,
    setError
  };
};

export default useCommentHandlers;