import React, { useState, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import ImageCard from './ImageCard';
import useImageHandlers from '../hooks/image/useImageHandlers';
import ContentEditor from './ContentEditor';
import '../css/ContentPreview.css';
import { authenticatedApiCall } from '../utils/authenticatedApiCall';

const ContentPreview = ({ content, onClose, onEdit, onDelete, isYourContent, startEditing }) => {
  const [isEditing, setIsEditing] = useState(startEditing);
  const [currentContent, setCurrentContent] = useState(content);
  const contentRef = useRef(null);
  const imagesRef = useRef(null);

  const [splitPosition, setSplitPosition] = useState(60);
  const isDraggingRef = useRef(false);

  const {
    buttonStates,
    handleDownload,
    handleCopyPrompt,
    handleCopyMarkdownLink,
    handleShowSettings,
    handleGetLikedByUsers,
    // eslint-disable-next-line
    clearError,
  } = useImageHandlers();

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  useEffect(() => {
    setIsEditing(startEditing);
    setCurrentContent(content);
  }, [startEditing, content]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveEdit = async (editedContent) => {
    await onEdit(currentContent._id.$oid, editedContent);
    setCurrentContent({ ...currentContent, content: editedContent });
    setIsEditing(false);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
  };

  const handleDelete = async (imageUrl) => {
    try {
      const response = await authenticatedApiCall('/delete-content-image', 'POST', {
        content_id: currentContent._id.$oid,
        image_url: imageUrl
      });

      if (response.success) {
        const updatedImageUrls = currentContent.image_urls.filter(url => url !== imageUrl);
        setCurrentContent({ ...currentContent, image_urls: updatedImageUrls });
      } else {
        console.error('Failed to delete image:', response.message);
      }
    } catch (error) {
      console.error('Error deleting image:', error);
    }
  };

  const handleMouseDown = (e) => {
    isDraggingRef.current = true;
    document.body.style.cursor = 'col-resize';
    document.querySelector('.cp-preview-content').classList.add('dragging');
  };

  const handleMouseMove = (e) => {
    if (!isDraggingRef.current) return;

    const container = document.querySelector('.cp-preview-content');
    const rect = container.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const newPosition = (x / rect.width) * 100;

    // Limit the range to prevent extreme resizing
    if (newPosition >= 20 && newPosition <= 80) {
      setSplitPosition(newPosition);
    }
  };

  const handleMouseUp = () => {
    isDraggingRef.current = false;
    document.body.style.cursor = 'default';
    document.querySelector('.cp-preview-content').classList.remove('dragging');
  };

  const containsLinks = (text) => {
    // eslint-disable-next-line
    const linkRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    // eslint-disable-next-line
    const wwwRegex = /(^|[^\/])(www\.[a-z0-9\-]+(\.[a-z]{2,})([^\s]*)?)/gi;
    const mailtoRegex = /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/gi;
    // eslint-disable-next-line
    const internalLinkRegex = /\[([^\]]+)\]\((\/|https?:\/\/|www\.|[a-zA-Z0-9\-]+\.[a-zA-Z]{2,})([^)]+)?\)/g;
    const anchorLinkRegex = /#[a-zA-Z0-9_-]+/g;

    const isHarmlessLink = (link) => link.includes('image.pollinations.ai');

    const linkMatches = text.match(linkRegex) || [];
    const wwwMatches = text.match(wwwRegex) || [];
    const mailtoMatches = text.match(mailtoRegex) || [];
    const internalLinkMatches = text.match(internalLinkRegex) || [];
    const anchorLinkMatches = text.match(anchorLinkRegex) || [];

    const filteredLinks = [...linkMatches, ...wwwMatches, ...internalLinkMatches].filter(
      (link) => !isHarmlessLink(link)
    );

    return filteredLinks.length > 0 || mailtoMatches.length > 0 || anchorLinkMatches.length > 0;
  };

  return (
    <div className="cp-preview-overlay" onClick={onClose}>
      <div className="cp-preview-content" onClick={(e) => e.stopPropagation()}>
        <button className="cp-preview-close" onClick={onClose}>&times;</button>

        <div className="cp-book-page left-page" style={{ flexBasis: `${splitPosition}%` }}>
          <div className="cp-page-content" ref={contentRef}>
            {isEditing ? (
              <>
                <div className="cp-warning">
                  <p>Warning: Do not add any inappropriate or harmful content. <a href="/blog/other/content-instructions" target="_blank" rel="noopener noreferrer">Read content instructions</a></p>
                </div>
                <ContentEditor
                  content={currentContent}
                  onSave={handleSaveEdit}
                  onCancel={handleCancelEdit}
                />
              </>
            ) : (
              <>
                {!isYourContent && containsLinks(currentContent.content) && (
                  <div className="cp-caution">
                    <p>Caution: This content might contain links. Be careful when clicking any link, especially in content you don't own. <a href="/blog/other/content-instructions" target="_blank" rel="noopener noreferrer">Learn more about content safety</a></p>
                  </div>
                )}
                <div className="cp-markdown-container">
                  <ReactMarkdown>{currentContent.content}</ReactMarkdown>
                </div>
                <p className="cp-preview-date">
                  Created at: {new Date(currentContent.created_at.$date).toLocaleString()}
                </p>
                {isYourContent && (
                  <div className="cp-preview-buttons">
                    <button className="cp-edit-button" onClick={handleEditClick}>Edit</button>
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        <div
          className="cp-preview-spine"
          onMouseDown={handleMouseDown}
        >
        </div>
        <div className="cp-book-page right-page" style={{ flexBasis: `${100 - splitPosition}%` }}>
          <div className="cp-page-content" ref={imagesRef}>
            <div className="cp-preview-images">
              {currentContent.image_urls.map((imageUrl, index) => (
                <ImageCard
                  key={index}
                  image={{
                    id: `preview-${index}`,
                    imageUrl: imageUrl,
                    title: `${currentContent.tool_name} Image ${index + 1}`,
                    username: currentContent.user_name || 'AI',
                    is_liked: false
                  }}
                  index={index}
                  copyPromptToClipboard={handleCopyPrompt}
                  handleCopyMarkdownLink={handleCopyMarkdownLink}
                  handleDownload={(url, id) => handleDownload(url, id, `${currentContent.tool_name}-${index + 1}`)}
                  buttonStates={buttonStates}
                  hideUserInfo={true}
                  isToolGenerated={true}
                  isShowingInContent={true}
                  isOwnContentImages={isYourContent}
                  handleDelete={() => handleDelete(imageUrl)}
                  handleShowSettings={handleShowSettings}
                  handleGetLikedByUsers={handleGetLikedByUsers}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentPreview;