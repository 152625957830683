// MoreOptions.js
import React from 'react';
import { Link } from 'react-router-dom';
import {
    Download, Clipboard, Loader, Check, Trash2, EyeOff, Eye,
    Image, ImageOff, Link as LinkIcon, Flag, Settings, User, Share2
} from 'lucide-react';

export const MoreOptions = ({
    showMoreOptions,
    image,
    buttonStates,
    handleShare,
    handleCopyClick,
    handleSettingsClick,
    onToggleAdultStatus,
    isLoggedIn,
    handleDownloadClick,
    isHidden,
    handleToggleHide,
    isBlurred,
    handleToggleBlur,
    handleCopyMarkdownLinkClick,
    handleSetAsProfileClick,
    handleDeleteClick,
    handleProfileImageLoad,
    handleReportClick,
    hideUserInfo,
    isAdmin,
    isLikedImage,
    isOwnContentImages,
    isOwnProfile,
    isShowingInContent,
    isToolGenerated,
    onDeleteImage,
    onRemoveReportedStatus,
    profileImageLoaded,
    handleViewLikesClick,
    loadingLikes,
    formatTimestamp,
    moreOptionsRef,
    setShowMoreOptions,
    setActiveMoreOptions
}) => {
    return (
        <div
            ref={moreOptionsRef}
            className={`ic-more-options ${showMoreOptions ? 'ic-show' : ''}`}
            onClick={(e) => e.stopPropagation()}
        >
            <div
                className="ic-close-more-options-button"
                onClick={(e) => {
                    e.stopPropagation();
                    setShowMoreOptions(false);
                    setActiveMoreOptions(false);
                }}
            >
                X
            </div>
            {!hideUserInfo && !isOwnProfile && (
                <div className="ic-user-info">
                    {image.userProfileImage && (
                        <Link to={`/profile/${image.username}`} className="ic-user-link" onClick={(e) => e.stopPropagation()}>
                            <div className={`ic-user-avatar-container ${image.badges?.social_ambassador ? 'ic-social-ambassador' : ''}`}>
                                <img
                                    src={image.userProfileImage}
                                    alt={image.username}
                                    className={`ic-user-avatar ${profileImageLoaded ? 'ic-loaded' : ''}`}
                                    onLoad={handleProfileImageLoad}
                                />
                                {image.badges?.social_ambassador && (
                                    <span className="ic-badge-icon" title="social ambassador badge">
                                        <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                                            <path d="m15,12c0,1.302-.839,2.402-2,2.816v-3.816h-2v3.816c-1.161-.414-2-1.514-2-2.816,0-1.708,1.819-3.67,3-4.708,1.181,1.038,3,3,3,4.708Zm7.08,4.175c.122,1.592-.451,3.167-1.595,4.31-1.144,1.143-2.711,1.718-4.31,1.595-1.039,1.212-2.558,1.92-4.175,1.92s-3.136-.708-4.175-1.92c-1.595.12-3.166-.452-4.31-1.595-1.144-1.144-1.717-2.718-1.595-4.31-1.212-1.039-1.92-2.558-1.92-4.175s.708-3.136,1.92-4.175c-.122-1.592.451-3.167,1.595-4.31,1.144-1.143,2.717-1.717,4.31-1.595,1.039-1.212,2.558-1.92,4.175-1.92s3.136.708,4.175,1.92c1.595-.121,3.167.452,4.31,1.595,1.144,1.144,1.717,2.718,1.595,4.31,1.212,1.039,1.92,2.558,1.92,4.175s-.708,3.136-1.92,4.175Zm-5.08-4.175c0-3.402-3.95-6.462-4.4-6.8l-.6-.45-.6.45c-.45.337-4.4,3.398-4.4,6.8,0,2.414,1.721,4.434,4,4.899v2.101h2v-2.101c2.279-.465,4-2.484,4-4.899Z" />
                                        </svg>
                                    </span>
                                )}
                            </div>
                        </Link>
                    )}
                    {!profileImageLoaded && (
                        <div className="ic-avatar-placeholder">
                            <User size={22} />
                        </div>
                    )}
                    <div className="ic-username-date">
                        <Link to={`/profile/${image.username}`} className="ic-user-link ic-username" onClick={(e) => e.stopPropagation()}>
                            {image.username}
                        </Link>
                        {!isToolGenerated && (
                            <div className="ic-image-date">{formatTimestamp(image.timestamp)}</div>
                        )}
                    </div>
                </div>
            )}
            {!isToolGenerated && (
                <div className="ic-image-title-mobile">{image.title}</div>
            )}
            <button
                onClick={handleDownloadClick}
                className="ic-action-button"
                title="Download"
                disabled={buttonStates[image.id]?.download === 'loading'}
            >

                {buttonStates[image.id]?.download === 'loading' ? (
                    <><Loader size={20} className="ic-action-icon ic-animate-spin" /><p>Downloading..</p></>
                ) : buttonStates[image.id]?.download === 'success' ? (
                    <><Check size={20} className="ic-action-icon" /><p>Downloded!</p></>
                ) : (
                    <><Download size={20} className="ic-action-icon" /><p>Download Image</p></>
                )}

            </button>
            {(!isToolGenerated || !isShowingInContent) && (
                <>
                    <button
                        onClick={handleShare}
                        className="ic-action-button"
                        title="Copy Image Share Link"
                        disabled={buttonStates[image.id]?.share === 'loading'}
                    >

                        {buttonStates[image.id]?.share === 'loading' ? (
                            <><Loader size={20} className="ic-action-icon ic-animate-spin" /><p>Copying..</p></>
                        ) : buttonStates[image.id]?.share === 'success' ? (
                            <><Check size={20} className="ic-action-icon" /><p>Copied!</p></>
                        ) : (
                            <><Share2 size={20} className="ic-action-icon" /><p>Copy Share Link</p></>
                        )}

                    </button>
                    <button
                        onClick={handleSettingsClick}
                        className="ic-action-button"
                        title="View Settings"
                        disabled={buttonStates[image.id]?.settings === 'loading'}
                    >
                        {buttonStates[image.id]?.settings === 'loading' ? (
                            <><Loader size={20} className="ic-action-icon ic-animate-spin" /><p>Loading...</p></>
                        ) : buttonStates[image.id]?.settings === 'success' ? (
                            <><Check size={20} className="ic-action-icon" /><p>Settings Loaded!</p></>
                        ) : (
                            <><Settings size={20} className="ic-action-icon" /><p>View Image Settings</p></>
                        )}
                    </button>
                    <button
                        onClick={handleViewLikesClick}
                        className="ic-action-button"
                        title="View Likes"
                        disabled={loadingLikes}
                    >
                        {loadingLikes ? (
                            <><Loader size={20} className="ic-action-icon ic-animate-spin" /><p>Loading...</p></>
                        ) : (
                            <><User size={20} className="ic-action-icon" /><p>View Likes</p></>
                        )}
                    </button>
                </>
            )}
            {isShowingInContent && (
                <button
                    onClick={handleCopyClick}
                    className="ic-action-button ic-copy-prompt"
                    title="Copy Prompt"
                    disabled={buttonStates[image.id]?.copy === 'loading'}
                >
                    {buttonStates[image.id]?.copy === 'loading' ? (
                        <><Loader size={20} className="ic-action-icon ic-animate-spin" /><p>Copying..</p></>
                    ) : buttonStates[image.id]?.copy === 'success' ? (
                        <><Check size={20} className="ic-action-icon" /><p>Copied!</p></>
                    ) : (
                        <><Clipboard size={20} className="ic-action-icon" /><p>Copy Prompt</p></>
                    )}
                </button>
            )}
            {!isShowingInContent && !isOwnProfile && (
                <button
                    className='ic-action-button ic-hide'
                    onClick={handleToggleHide}
                    title={isHidden ? "Show Image" : "Hide Image"}
                >
                    {isHidden ? (
                        <><Eye size={20} className='ic-action-icon ic-info' /><p>Show Image</p></>
                    ) : (
                        <><EyeOff size={20} className='ic-action-icon ic-info' /><p>Hide Image</p></>
                    )}
                </button>
            )}
            {image.isAdult && isLoggedIn && (
                <button
                    className='ic-action-button ic-blur'
                    onClick={handleToggleBlur}
                    title={isBlurred ? "Unblur Image" : "Blur Image"}
                >
                    {isBlurred ? (
                        <><Image size={20} className='ic-action-icon ic-info' /><p>Unblur Image</p></>
                    ) : (
                        <><ImageOff size={20} className='ic-action-icon ic-info' /><p>Blur Image</p></>
                    )}
                </button>
            )}
            {isOwnProfile && !image.isAdult && (
                <button
                    onClick={handleSetAsProfileClick}
                    className="ic-action-button"
                    title="Set as Profile Picture"
                    disabled={buttonStates[image.id]?.setProfile === 'loading'}
                >
                    {buttonStates[image.id]?.setProfile === 'loading' ? (
                        <><Loader size={20} className="ic-action-icon ic-animate-spin" /><p>Setting Profile...</p></>
                    ) : buttonStates[image.id]?.setProfile === 'success' ? (
                        <><Check size={20} className="ic-action-icon" /><p>Done!</p></>
                    ) : (
                        <><User size={20} className="ic-action-icon" /><p>Set as Profile Picture</p></>
                    )}
                </button>
            )}
            {(isOwnProfile || isOwnContentImages) && !isLikedImage && (
                <>
                    <button
                        onClick={handleCopyMarkdownLinkClick}
                        className="ic-action-button"
                        title="Copy Markdown Link"
                        disabled={buttonStates[image.id]?.copyLink === 'loading'}
                    >

                        {buttonStates[image.id]?.copyLink === 'loading' ? (
                            <><Loader size={20} className="ic-action-icon ic-animate-spin" /><p>Copying..</p></>
                        ) : buttonStates[image.id]?.copyLink === 'success' ? (
                            <><Check size={20} className="ic-action-icon" /><p>Copied!</p></>
                        ) : (
                            <><LinkIcon size={20} className="ic-action-icon" /><p>Copy Markdown Link</p></>
                        )}

                    </button>
                    <button
                        onClick={handleDeleteClick}
                        className="ic-action-button ic-delete"
                        title="Delete"
                    >

                        <Trash2 size={20} className="ic-action-icon ic-delete" />
                        Delete Image
                    </button>
                </>
            )}
            {!isToolGenerated && !isOwnProfile && !isAdmin && (
                <button
                    onClick={handleReportClick}
                    className="ic-action-button ic-report"
                    title="Report Image"
                    disabled={buttonStates[image.id]?.report === 'loading'}
                >

                    {buttonStates[image.id]?.report === 'loading' ? (
                        <><Loader size={20} className="ic-action-icon ic-animate-spin" /><p>Reporting..</p></>
                    ) : buttonStates[image.id]?.report === 'success' ? (
                        <><Check size={20} className="ic-action-icon" /><p>Reported!</p></>
                    ) : (
                        <><Flag size={20} className="ic-action-icon" /><p>Report Image</p></>
                    )}

                </button>
            )}
            {isAdmin && (
                <>
                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            onToggleAdultStatus();
                        }}
                        className="ic-action-button"
                        title="Change Adult Status"
                    >

                        <Flag size={20} className="ic-action-icon" />
                        Change Adult Status
                    </button>
                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            onRemoveReportedStatus();
                        }}
                        className="ic-action-button"
                        title="Remove Reported Status"
                    >

                        <Flag size={20} className="ic-action-icon" />
                        Remove Report by Admin
                    </button>
                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            onDeleteImage();
                        }}
                        className="ic-action-button ic-delete"
                        title="Delete Image"
                    >

                        <Trash2 size={20} className="ic-action-icon ic-delete" />
                        Delete Image by Admin
                    </button>
                </>
            )}
        </div>
    );
};