import { authenticatedApiCall } from '../../utils/authenticatedApiCall'; 

export const reportImage = async (imageId) => {
  try {
    const response = await authenticatedApiCall('/report-image', 'POST', { imageId });
    if (response && response.success) {
      return { success: true, message: 'Image reported successfully' };
    } else {
      return { success: false, message: response?.error || 'Failed to report image' };
    }
  } catch (error) {
    console.error('Error reporting image:', error);
    return { success: false, message: error.message || 'Failed to report image' };
  }
};