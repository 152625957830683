// DesignViewer.js
import React, { useState } from 'react';
import { X, Loader2, Download } from 'lucide-react';
import { authenticatedApiCall } from '../../../utils/authenticatedApiCall';
import './DesignViewer.css';
import { useNavigate } from 'react-router-dom';
import { isLoggedIn } from '../../../utils/cookies';

const DesignViewer = ({ design, onClose, type, customProps = {} }) => {
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [currentImageUrl, setCurrentImageUrl] = useState(design.image_url);
  // eslint-disable-next-line
  const [isSaved, setIsSaved] = useState(false);
  const navigate = useNavigate();

  const handleImageLoad = async () => {
    setIsImageLoading(false);

    if (!isLoggedIn()) {
      return;
    }

    try {
      const response = await authenticatedApiCall(
        `/save-${type}-to-folder`,
        'POST',
        {
          imageUrl: currentImageUrl
        }
      );

      if (response.success) {
        setIsSaved(true);
      }
    } catch (error) {
      console.error(`Error saving ${type}:`, error);
    }
  };

  const handleUpdateDesign = async () => {
    if (!isLoggedIn()) {
      navigate('/login');
      return;
    }

    if (!input.trim()) return;

    setIsLoading(true);
    try {
      const response = await authenticatedApiCall(
        `/update-${type}-design`,
        'POST',
        {
          imageUrl: currentImageUrl,
          userPrompt: input
        }
      );

      if (response.success && response.newImageUrl) {
        setIsImageLoading(true);
        setCurrentImageUrl(response.newImageUrl);
        setIsSaved(false);
      }
    } catch (error) {
      console.error(`Error updating ${type}:`, error);
    } finally {
      setIsLoading(false);
      setInput('');
    }
  };

  const handleDownload = async () => {
    try {
      const response = await fetch(currentImageUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${type}-design.png`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error(`Error downloading ${type}:`, error);
    }
  };

  const handleKeyPress = (e) => {
    if (!isLoggedIn()) {
      navigate('/login');
      return;
    }
    if (e.key === 'Enter' && !isLoading && input.trim()) {
      handleUpdateDesign();
    }
  };

  // Merge default and custom placeholder text
  const placeholderText = customProps.inputPlaceholder || `Describe how you want to modify this ${type}...`;

  return (
    <div className="ldv-overlay" onClick={onClose}>
      <div className="ldv-container" onClick={e => e.stopPropagation()}>
        <button
          onClick={onClose}
          className="ldv-close-button"
          aria-label="Close viewer"
        >
          <X size={24} />
        </button>

        <div className="ldv-content">
          <h2 className="ldv-title">Update {type.charAt(0).toUpperCase() + type.slice(1)} Design</h2>

          <div className="ldv-image-wrapper">
            {(isLoading || isImageLoading) && (
              <div className="ldv-loading-overlay">
                <Loader2 className="animate-spin" size={48} />
                <p className="mt-2">
                  {isLoading ? `Generating new ${type}...` : `Loading new generated ${type}...`}
                </p>
              </div>
            )}
            <img
              src={currentImageUrl}
              alt={`${type} design`}
              className={`ldv-image ${(isLoading || isImageLoading) ? 'opacity-50' : ''}`}
              onLoad={handleImageLoad}
            />
            <button
              onClick={handleDownload}
              className="ldv-download-button"
              aria-label={`Download ${type}`}
              disabled={isLoading || isImageLoading}
            >
              <Download size={20} />
              <span className="ldv-download-text">Download</span>
            </button>
          </div>

          <div className="ldv-input-wrapper">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={handleKeyPress}
              className="ldv-input"
              placeholder={placeholderText}
              disabled={isLoading || isImageLoading}
            />
            <button
              onClick={handleUpdateDesign}
              className="ldv-submit-button"
              disabled={isLoading || isImageLoading || !input.trim()}
            >
              {isLoading ? 'Updating...' : `Update ${type.charAt(0).toUpperCase() + type.slice(1)}`}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesignViewer;