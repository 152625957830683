// src/utils/image/copyPrompt.js

import { extractPromptFromUrl } from './imageDownload';

export const copyPromptToClipboard = async (imageUrl) => {
  try {
    const prompt = extractPromptFromUrl(imageUrl);
    await navigator.clipboard.writeText(prompt);
    return { success: true, message: 'Prompt copied to clipboard!' };
  } catch (err) {
    console.error('Failed to copy prompt: ', err);
    return { success: false, message: 'Failed to copy prompt' };
  }
};

export const copyMarkdownLinkToClipboard = async (imageUrl, imageTitle) => {
  try {
    const markdownLink = `![${imageTitle}](${imageUrl})`;
    await navigator.clipboard.writeText(markdownLink);
    return { success: true, message: 'Markdown link copied to clipboard!' };
  } catch (err) {
    console.error('Failed to copy markdown link: ', err);
    return { success: false, message: 'Failed to copy markdown link' };
  }
};