import React, { useEffect } from 'react';
import './App.css';
import Header from './Components/Header';
import Home from './Components/Home';
import ScrollToTop from './Components/ScrollToTop';
import GoogleAnalytics from './Components/Analytics/GoogleAnalytics';
import { setCookie, getCookie } from './utils/cookies';
import ImageGenerator from './Components/Tools/ImageGenerator'
import SignIn from './Components/Auth/SignIn'
import PrivateRoute from './Components/Auth/PrivateRoute';
import { isImageGenerationLimited } from './utils/cookies';
import Profile from './Components/User/Profile';
import Explore from './Components/User/Explore';
import About from './Landing/About';
import Tools from './Components/Tools';
import ImageDetailView from './Components/ImageDetailView';
import ContentGenerator from './Components/Tools/ContentGenerator';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import Blog from './Components/Blog';
import ContentViewer from './Components/ContentViewer';
import AdminDashboard from './Components/Admin/AdminDashboard';
import AdminRoute from './Components/Auth/AdminRoute';
import { MoreOptionsProvider } from './Components/ImageCard';
import GithubCallBack from './Components/Auth/GithubCallBack';
import LogoGenerator from './Components/Tools/LogoGenerator';
import ThumbnailGenerator from './Components/Tools/ThumbnailGenerator';

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

function App() {
  useEffect(() => {
    const userSession = getCookie('user_session');

    if (!userSession) {
      const newSessionId = `session_${Math.random().toString(36).substring(2)}`;
      setCookie('user_session', newSessionId, 15);
    }
  }, []);

  return (
    <>
      <MoreOptionsProvider>
        <Router>
          <GoogleAnalytics />
          <ScrollToTop />
          <Header />
          <Routes>
            <Route path="/" element={<About />} />
            <Route path="/login" element={<SignIn />} />

            <Route element={<PrivateRoute />}>
              <Route path='/profile' element={<Profile />} />
              {isImageGenerationLimited() && (
                <Route path='/generate-image' element={<ImageGenerator />} />
              )}
            </Route>

            <Route element={<AdminRoute />}>
              <Route path="/admin" element={<AdminDashboard />} />
            </Route>

            {!isImageGenerationLimited() && (
              <Route path='/generate-image' element={<ImageGenerator />} />
            )}
            <Route path="/home" element={<Home />} />
            <Route path="/profile/:username" element={<Profile />} />
            <Route path='/explore' element={<Explore />} />
            <Route path="/create" element={<Tools />} />
            <Route path="/image/:imageId" element={<ImageDetailView />} />
            <Route path="/generate-content" element={<ContentGenerator />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<TermsOfService />} />
            <Route path="/docs" element={<Blog />} />
            <Route path="/docs/:category/:post" element={<Blog />} />
            <Route path="/content" element={<ContentViewer />} />
            <Route path="/github-callback" element={<GithubCallBack />} />
            <Route path="/generate-logo" element={<LogoGenerator />} />
            <Route path="/generate-thumbnail" element={<ThumbnailGenerator />} />
          </Routes>
        </Router>
      </MoreOptionsProvider>
    </>
  );
}

export default App;