import React, { useState, useEffect, useRef } from 'react';
import { authenticatedApiCall } from '../utils/authenticatedApiCall';
import '../css/ComparisonPopup.css';

const COMPARISON_LAST_SHOWN_KEY = 'comparisonLastShown';
const HOURS_24 = 24 * 60 * 60 * 1000;

const ComparisonPopup = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [imagePairs, setImagePairs] = useState([]);
    const [currentPairIndex, setCurrentPairIndex] = useState(0);
    const [votedPairs, setVotedPairs] = useState(new Set());
    const [skippedPairs, setSkippedPairs] = useState(new Set());
    const [likeInProgress, setLikeInProgress] = useState({});
    const imagePairsRef = useRef(imagePairs);

    const preloadImages = async (pairs) => {
        const loadPromises = pairs.flatMap(pair =>
            pair.images.map(image => new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = resolve;
                img.onerror = reject;
                img.src = image.imageUrl;
            }))
        );

        try {
            await Promise.all(loadPromises);
            setIsOpen(true);
        } catch (error) {
            console.error('Error preloading images:', error);
        }
    };

    useEffect(() => {
        imagePairsRef.current = imagePairs;
    }, [imagePairs]);

    useEffect(() => {
        const lastShownTime = localStorage.getItem(COMPARISON_LAST_SHOWN_KEY);
        const currentTime = Date.now();

        if (!lastShownTime || (currentTime - parseInt(lastShownTime)) >= HOURS_24) {
            const timer = setTimeout(() => {
                fetchImagePairs();
            }, 5 * 60 * 1000);

            return () => clearTimeout(timer);
        }
        // eslint-disable-next-line
    }, []);

    const fetchImagePairs = async () => {
        try {
            const response = await authenticatedApiCall('/get-related-pairs', 'GET');
            if (response && response.length > 0) {
                setImagePairs(response);
                await preloadImages(response);
                localStorage.setItem(COMPARISON_LAST_SHOWN_KEY, Date.now().toString());
            }
        } catch (error) {
            console.error('Error fetching image pairs:', error);
        }
    };

    const handleImageClick = async (image) => {
        if (likeInProgress[image.id]) return;

        // Immediately record the vote and move to next pair
        const newVotedPairs = new Set(votedPairs);
        newVotedPairs.add(currentPairIndex);
        setVotedPairs(newVotedPairs);

        // Start optimistic update
        setLikeInProgress(prev => ({ ...prev, [image.id]: true }));
        const currentLikeCount = image.likeCount;
        const isCurrentlyLiked = image.is_liked;

        // Optimistically update the image pair
        setImagePairs(prevPairs => prevPairs.map((pair, index) => ({
            ...pair,
            images: pair.images.map(img =>
                img.id === image.id
                    ? {
                        ...img,
                        likeCount: isCurrentlyLiked ? currentLikeCount - 1 : currentLikeCount + 1,
                        is_liked: !isCurrentlyLiked
                    }
                    : img
            )
        })));

        // Move to next pair immediately
        moveToNextPair();

        // Handle the like in the background
        try {
            const response = await authenticatedApiCall(`/update-like-count/${image.id}`, 'POST', {
                increment: isCurrentlyLiked ? -1 : 1
            });

            if (!response.success) {
                throw new Error(response.error || 'Failed to update like count');
            }
        } catch (error) {
            console.error('Error updating like count:', error);

            // Revert the optimistic update if we're still mounted
            setImagePairs(prevPairs => prevPairs.map((pair, index) => ({
                ...pair,
                images: pair.images.map(img =>
                    img.id === image.id
                        ? { ...img, likeCount: currentLikeCount, is_liked: isCurrentlyLiked }
                        : img
                )
            })));
        } finally {
            setLikeInProgress(prev => ({ ...prev, [image.id]: false }));
        }
    };

    const handleSkip = () => {
        const newSkippedPairs = new Set(skippedPairs);
        newSkippedPairs.add(currentPairIndex);
        setSkippedPairs(newSkippedPairs);
        moveToNextPair();
    };

    const moveToNextPair = () => {
        const totalResponded = new Set([...votedPairs, ...skippedPairs]).size;

        if (totalResponded >= imagePairs.length - 1) {
            setIsOpen(false);
            return;
        }

        if (currentPairIndex < imagePairs.length - 1) {
            setCurrentPairIndex(prev => prev + 1);
        }
    };

    if (!isOpen || imagePairs.length === 0) return null;

    const currentPair = imagePairs[currentPairIndex];

    return (
        <div className="cip-overlay">
            <div className="cip-container">
                <div className="cip-header">
                    <h2 className="cip-title">
                        Please select one image that you prefer
                    </h2>
                    <p className="cip-subtitle">
                        Both images are related to {currentPair.tags.join(' and ')}
                    </p>
                </div>

                <div className="cip-image-grid">
                    {currentPair.images.map((image) => (
                        <div
                            key={image.id}
                            className="cip-image-wrapper"
                            onClick={() => handleImageClick(image)}
                        >
                            <img
                                src={image.imageUrl}
                                alt={image.description || 'Comparison image'}
                                className="cip-image"
                            />
                            <div className="cip-like-count">
                                {image.likeCount || 0} likes
                            </div>
                        </div>
                    ))}
                </div>

                <div className="cip-footer">
                    <div className="cip-footer-content">
                        <span className="cip-progress">
                            Pair {currentPairIndex + 1} of {imagePairs.length}
                        </span>
                        <button
                            onClick={handleSkip}
                            className="cip-skip-button"
                        >
                            Skip
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ComparisonPopup;