import React, { useState, useEffect } from 'react';
import RandomLoader from '../../RandomLoader';
import { authenticatedApiCall } from '../../../utils/authenticatedApiCall';
import useMessage from '../../../hooks/useNotify';
import { ChevronDown } from 'lucide-react';

const GeneratedImage = ({
  isLoading,
  generatedImageUrls,
  imageDimensions,
  canvasRefs,
  handleDownload,
  isMobileView
}) => {
  const [loadingStates, setLoadingStates] = useState([]);
  const [submittingDesign, setSubmittingDesign] = useState([]);
  const [submittedDesigns, setSubmittedDesigns] = useState([]);
  const [selectedDesignTypes, setSelectedDesignTypes] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState([]);
  const { setMessage } = useMessage();

  const designTypes = [
    { value: 'logo', label: 'Logo Design' },
    { value: 'thumbnail', label: 'Thumbnail Design' }
  ];

  useEffect(() => {
    if (generatedImageUrls && generatedImageUrls.length > 0) {
      setLoadingStates(new Array(generatedImageUrls.length).fill(true));
      setSubmittingDesign(new Array(generatedImageUrls.length).fill(false));
      setSubmittedDesigns(new Array(generatedImageUrls.length).fill(false));
      setSelectedDesignTypes(new Array(generatedImageUrls.length).fill(''));
      setDropdownOpen(new Array(generatedImageUrls.length).fill(false));
    }
  }, [generatedImageUrls]);

  const handleImageLoad = (index) => {
    setLoadingStates(prevStates => {
      const newStates = [...prevStates];
      newStates[index] = false;
      return newStates;
    });
  };

  const handleDesignSubmission = async (url, index) => {
    const designType = selectedDesignTypes[index];
    if (!designType) {
      setMessage('Please select a design type first', 'error');
      return;
    }

    try {
      setSubmittingDesign(prev => {
        const newStates = [...prev];
        newStates[index] = true;
        return newStates;
      });

      const response = await authenticatedApiCall(
        `/submit-${designType}-design`,
        'POST',
        {
          imageUrl: url,
        }
      );

      if (response.success) {
        setMessage(`${designType.charAt(0).toUpperCase() + designType.slice(1)} design submitted successfully!`, 'success');
        setSubmittedDesigns(prev => {
          const newStates = [...prev];
          newStates[index] = true;
          return newStates;
        });
      } else {
        throw new Error(response.error || `Failed to submit ${designType} design`);
      }
    } catch (error) {
      setMessage(error.message || 'Failed to submit design', 'error');
    } finally {
      setSubmittingDesign(prev => {
        const newStates = [...prev];
        newStates[index] = false;
        return newStates;
      });
    }
  };

  const toggleDropdown = (index) => {
    setDropdownOpen(prev => {
      const newStates = [...prev];
      newStates[index] = !newStates[index];
      return newStates;
    });
  };

  const selectDesignType = (index, type) => {
    setSelectedDesignTypes(prev => {
      const newTypes = [...prev];
      newTypes[index] = type;
      return newTypes;
    });
    setDropdownOpen(prev => {
      const newStates = [...prev];
      newStates[index] = false;
      return newStates;
    });
  };

  if (isLoading || !generatedImageUrls || generatedImageUrls.length === 0) return null;

  const isMultipleImages = generatedImageUrls.length > 1;

  return (
    <div className={`imgen-generated-images ${isMobileView ? 'mobile' : 'desktop'}`}>
      <h2 className="imgen-image-title">Generated Images</h2>
      <div className={`imgen-images-grid ${isMultipleImages ? 'multi-column' : ''}`}>
        {generatedImageUrls.map((url, index) => (
          <div
            key={`${url}-${index}-${isMobileView ? 'mobile' : 'desktop'}`}
            className={`imgen-generated-image-container ${isMultipleImages ? 'grid-item' : ''}`}
          >
            {loadingStates[index] ? (
              <div className="imgen-loading-spinner">
                <RandomLoader />
              </div>
            ) : (
              <>
                <div
                  className="imgen-image-wrapper"
                  style={{
                    width: isMultipleImages ? '100%' : imageDimensions.width,
                    height: 'fit-content',
                    maxWidth: '100%',
                    margin: '0 auto',
                    display: isMobileView ?
                      window.innerWidth < 768 ? 'block' : 'none' :
                      window.innerWidth >= 768 ? 'block' : 'none'
                  }}
                >
                  <canvas
                    ref={el => {
                      canvasRefs.current[index] = el;
                      if (el) {
                        const img = new Image();
                        img.crossOrigin = 'Anonymous';
                        img.onload = () => {
                          const ctx = el.getContext('2d');
                          el.width = img.width;
                          el.height = img.height;
                          ctx.drawImage(img, 0, 0);
                          handleImageLoad(index);
                        };
                        img.src = url;
                      }
                    }}
                    className="imgen-generated-image"
                    style={{
                      display: 'block',
                      width: '100%',
                      height: 'auto'
                    }}
                  />
                  <div className="imgen-image-overlay"></div>
                </div>
                <div className="imgen-action-buttons">
                  <button
                    onClick={() => handleDownload(url, index)}
                    className="imgen-download-button"
                  >
                    Download Image
                  </button>
                  <div className="imgen-submit-container">
                    <div className="imgen-submit-group">
                      <button
                        onClick={() => toggleDropdown(index)}
                        className="imgen-submit-type-button"
                        disabled={submittingDesign[index] || submittedDesigns[index]}
                      >
                        <span className="imgen-submit-type-text">
                          {selectedDesignTypes[index] ? 
                            `Submit as ${selectedDesignTypes[index].charAt(0).toUpperCase() + selectedDesignTypes[index].slice(1)}` : 
                            'Submit as...'}
                        </span>
                        <ChevronDown className="imgen-chevron-icon" />
                      </button>
                      {selectedDesignTypes[index] && (
                        <button
                          onClick={() => handleDesignSubmission(url, index)}
                          className="imgen-submit-button"
                          disabled={submittingDesign[index] || submittedDesigns[index]}
                        >
                          {submittingDesign[index] ? 'Submitting...' : 
                           submittedDesigns[index] ? 'Submitted for Approval' : 
                           'Submit'}
                        </button>
                      )}
                    </div>
                    {dropdownOpen[index] && (
                      <div className="imgen-dropdown-menu">
                        {designTypes.map((type) => (
                          <button
                            key={type.value}
                            onClick={() => selectDesignType(index, type.value)}
                            className="imgen-dropdown-item"
                          >
                            {type.label}
                          </button>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default GeneratedImage;