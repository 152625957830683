import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { authenticatedApiCall } from '../../utils/authenticatedApiCall';
import { incrementImageGenerationCount, isImageGenerationLimited, isLoggedIn } from '../../utils/cookies';
import '../../css/ImageGenerator.css'
import useMessage from '../../hooks/useNotify';
import AdultContentWarning from './AdultContentWarning';
import { useDocumentHead } from '../../hooks/useDocumentHead'

import PromptSection from './ImageGenerator/PromptSection';
import SettingsPanel from './ImageGenerator/SettingsPanel';
import GeneratedImage from './ImageGenerator/GeneratedImage';
import { fieldOptions, dimensionPresets } from './ImageGenerator/constants';


const ImageGenerator = () => {
    const [formData, setFormData] = useState({
        prompt: '',
        width: 1080,
        height: 1900,
        artStyle: '',
        model: '',
        seed: '224488',
        perspective: '',
        theme: '',
        lighting: '',
        colorPalette: '',
        composition: '',
        enhance: false,
        dimensionPreset: 'default',
        numImages: 1,
    });

    const [generatedImageUrls, setGeneratedImageUrls] = useState([]);
    const desktopCanvasRefs = useRef([]);
    const mobileCanvasRefs = useRef([]);

    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [wordCount, setWordCount] = useState(0);
    const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
    const [mobileView, setMobileView] = useState('prompt');
    const [isPromptValid, setIsPromptValid] = useState(true);
    // eslint-disable-next-line
    const [isImageSaved, setIsImageSaved] = useState(false);
    const saveAttemptedRef = useRef(false);
    const [adultImageCount, setAdultImageCount] = useState(0);
    const [hasSavedImages, setHasSavedImages] = useState(false);

    const { MessageDisplay, setMessage, clearMessage } = useMessage();

    const generateRandomSeed = () => {
        const randomSeed = Math.floor(Math.random() * 1000000).toString();
        setFormData(prev => ({
            ...prev,
            seed: randomSeed
        }));
    };

    const displayImageOnCanvas = useCallback((imageUrl, index) => {
        const desktopCanvas = desktopCanvasRefs.current[index];
        const mobileCanvas = mobileCanvasRefs.current[index];

        const img = new Image();
        img.crossOrigin = 'Anonymous';

        let loadAttempts = 0;
        const maxAttempts = 3;

        const loadImage = () => {
            loadAttempts++;
            img.onload = () => {
                setImageDimensions({ width: img.width, height: img.height });

                // Draw on desktop canvas
                if (desktopCanvas) {
                    desktopCanvas.width = img.width;
                    desktopCanvas.height = img.height;
                    const desktopCtx = desktopCanvas.getContext('2d');
                    desktopCtx.drawImage(img, 0, 0);
                }

                // Draw on mobile canvas
                if (mobileCanvas) {
                    mobileCanvas.width = img.width;
                    mobileCanvas.height = img.height;
                    const mobileCtx = mobileCanvas.getContext('2d');
                    mobileCtx.drawImage(img, 0, 0);
                }

            };

            img.onerror = () => {
                if (loadAttempts < maxAttempts) {
                    setTimeout(loadImage, 2000);
                } else {
                    setMessage("Failed to load the image. Please try again.", "error");
                    setGeneratedImageUrls(prev => prev.filter((_, i) => i !== index));
                }
            };

            img.src = imageUrl;
        };

        loadImage();
    }, [setMessage]);
    useDocumentHead(
        'Image Generator - Create Unique AI Images Instantly | AI Tools',
        'Generate stunning, high-quality images instantly with our AI-powered Image Generator. Perfect for artists, creators, and marketers seeking unique visuals tailored to their needs. Try it free on AI Tools!'
    );

    useEffect(() => {
        generatedImageUrls.forEach((url, index) => {
            if (url) {
                displayImageOnCanvas(url, index);
            }
        });
    }, [generatedImageUrls, displayImageOnCanvas]);

    useEffect(() => {
        if (isImageGenerationLimited()) {
            navigate('/login', { state: { from: '/generate-image' } });
        }
    }, [navigate]);

    const saveImageToFolder = useCallback(async (imageUrls) => {
        if (!isLoggedIn() || hasSavedImages || saveAttemptedRef.current) return;

        saveAttemptedRef.current = true;

        try {
            const response = await authenticatedApiCall(
                '/save-images-to-folder',
                'POST',
                { imageUrls }
            );

            if (response.success) {
                setHasSavedImages(true);
            } else {
                setMessage(response.error || "Failed to save images", 'error');
            }
        } catch (error) {
            setMessage(error.message || "An unexpected error occurred", 'error');
        } finally {
            setTimeout(() => clearMessage(), 3000);
        }
    }, [hasSavedImages, setMessage, clearMessage]);

    const handleImageLoad = useCallback(() => {

        if (isLoggedIn() && !hasSavedImages && generatedImageUrls.length > 0) {
            saveImageToFolder(generatedImageUrls);
        }
    }, [hasSavedImages, generatedImageUrls, saveImageToFolder]);

    useEffect(() => {
        if (generatedImageUrls.length > 0) {
            setHasSavedImages(false);
            saveAttemptedRef.current = false;
        }
    }, [generatedImageUrls]);

    useEffect(() => {
        generatedImageUrls.forEach((url, index) => {
            if (url) {
                const img = new Image();
                img.onload = handleImageLoad;
                img.src = url;
            }
        });
    }, [generatedImageUrls, handleImageLoad]);

    const handleChange = useCallback((e) => {
        const { name, value, type } = e.target;

        setFormData(prevData => {
            let newValue = value;

            if (name === 'prompt') {
                const words = value.split(/\s+/);
                const truncatedWords = words.map(word => word.slice(0, 35));
                newValue = truncatedWords.join(' ');
                const newWordCount = truncatedWords.filter(word => word.length > 0).length;

                if (newWordCount > 400) {
                    newValue = truncatedWords.slice(0, 400).join(' ');
                    setMessage('Prompt is limited to 600 words, each word up to 35 characters.', 'error');
                }

                setWordCount(Math.min(newWordCount, 400));
            } else if (name === 'dimensionPreset') {
                // Update width and height based on preset
                if (value !== 'custom') {
                    return {
                        ...prevData,
                        dimensionPreset: value,
                        width: dimensionPresets[value].width,
                        height: dimensionPresets[value].height
                    };
                }
            } else if (name === 'width' || name === 'height') {
                // Allow any number input, including empty string
                newValue = value === '' ? '' : Number(value);
            } else if (type === 'number') {
                newValue = Number(value);
            }

            return { ...prevData, [name]: newValue };
        });
        // eslint-disable-next-line
    }, [setMessage]);


    const handleImprovePrompt = async () => {
        if (!formData.prompt) {
            setMessage("Please enter a prompt to improve.", 'error');
            return;
        }
        setMessage("Improving your prompt...", 'info');

        const {
            artStyle,
            perspective,
            theme,
            lighting,
            colorPalette,
            composition,
            mood
        } = formData;

        const options = [
            artStyle && `Art Style: ${artStyle}`,
            perspective && `Perspective: ${perspective}`,
            theme && `Theme: ${theme}`,
            lighting && `Lighting: ${lighting}`,
            colorPalette && `Color Palette: ${colorPalette}`,
            composition && `Composition Focus: ${composition}`,
            mood && `Mood: ${mood}`
        ].filter(Boolean).join(', ');

        const fullPrompt = options ? `${formData.prompt}. ${options}` : formData.prompt;

        try {
            const response = await authenticatedApiCall(
                '/improve-prompt',
                'POST',
                { prompt: fullPrompt }
            );
            if (response.improvedPrompt) {
                setFormData(prevData => ({
                    ...prevData,
                    prompt: response.improvedPrompt
                }));
                setWordCount(response.improvedPrompt.split(/\s+/).filter(word => word.length > 0).length);
                setMessage("Prompt improved successfully!", 'success');
            }
        } catch (error) {
            setMessage("Failed to improve prompt. Please try again.", 'error');
        } finally {
            clearMessage();
        }
    };

    const handleRandomPrompt = async () => {
        setMessage("Generating random prompt...", 'info');
        try {
            const response = await authenticatedApiCall('/random-prompt', 'GET');
            if (response.randomPrompt) {
                setFormData(prevData => ({
                    ...prevData,
                    prompt: response.randomPrompt
                }));
                setWordCount(response.randomPrompt.split(/\s+/).filter(word => word.length > 0).length);
                setMessage("Random prompt generated successfully!", 'success');
                setTimeout(() => {
                    clearMessage();
                }, 3000);
            }
        } catch (error) {
            setMessage("Failed to generate random prompt. Please try again.", 'error');
            setTimeout(() => {
                clearMessage();
            }, 3000);
        }
    };

    const isUserRestricted = () => {
        const restrictExpiresAt = localStorage.getItem('restrictExpiresAt');
        if (!restrictExpiresAt) return false;

        const expirationDate = new Date(restrictExpiresAt);
        return expirationDate > new Date();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isUserRestricted()) {
            const restrictExpiresAt = localStorage.getItem('restrictExpiresAt');
            const expirationDate = new Date(restrictExpiresAt);
            const now = new Date();

            // Calculate remaining time
            const timeDiff = expirationDate - now;
            const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
            const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

            let timeMessage;
            if (days > 0) {
                timeMessage = `${days} day${days > 1 ? 's' : ''}`;
            } else if (hours > 0) {
                timeMessage = `${hours} hour${hours > 1 ? 's' : ''}`;
            } else {
                timeMessage = `${minutes} minute${minutes > 1 ? 's' : ''}`;
            }

            setMessage(`"Your account is temporarily under restriction for ${timeMessage} due to continuous violations of terms of service. Further violations may result in a permanent ban.`, 'error');
            setIsLoading(false);
            return;
        }

        if (!isPromptValid) {
            setMessage("Please revise your prompt to remove explicit content.", 'error');
            setTimeout(() => setMessage(null, null), 3000);
            return;
        }
        if (isImageGenerationLimited()) {
            navigate('/login', { state: { from: '/generate-image' } });
            return;
        }

        if (isLoggedIn() && adultImageCount >= 30) {
            return; // AdultContentWarning component will handle the UI
        }

        // Validate and adjust width and height before submitting
        const validatedFormData = {
            ...formData,
            width: Math.max(350, Math.min(2800, Number(formData.width) || 350)),
            height: Math.max(350, Math.min(2800, Number(formData.height) || 350))
        };

        // Remove seed field if multiple images are selected
        if (validatedFormData.numImages > 1) {
            delete validatedFormData.seed;
        }

        setFormData(validatedFormData); // Update the form data with validated values
        setIsLoading(true);
        setGeneratedImageUrls([]);
        setIsImageSaved(false);
        saveAttemptedRef.current = false;

        const {
            prompt,
            artStyle,
            perspective,
            theme,
            lighting,
            colorPalette,
            composition,
            mood
        } = validatedFormData;

        const options = [
            artStyle && `Art Style: ${artStyle}`,
            perspective && `Perspective: ${perspective}`,
            theme && `Theme: ${theme}`,
            lighting && `Lighting: ${lighting}`,
            colorPalette && `Color Palette: ${colorPalette}`,
            composition && `Composition Focus: ${composition}`,
            mood && `Mood: ${mood}`
        ].filter(Boolean).join(', ');

        const fullPrompt = options ? `${prompt}. ${options}` : prompt;

        try {
            setMessage("Generating...", 'info');
            const response = await authenticatedApiCall(
                process.env.REACT_APP_IMAGE,
                'POST',
                { ...validatedFormData, prompt: fullPrompt }
            );

            if (response.imageUrls) {
                setGeneratedImageUrls(response.imageUrls);
                if (!isLoggedIn()) {
                    incrementImageGenerationCount();
                } else if (response.adult_images_count !== undefined) {
                    setAdultImageCount(response.adult_images_count);
                }
            } else {
                throw new Error('No images in response');
            }
        } catch (error) {
            if (error.response && error.response.status === 403 && error.response.data.error) {
                console.log("endteer")
                if (error.response.data.restrict_expires_at) {
                    console.log("endteer2")
                    localStorage.setItem('restrictExpiresAt', error.response.data.restrict_expires_at);
                }
                setMessage(error.response.data.error, 'error');
                setIsLoading(false);
                return;
            }

            setMessage("Failed to generate image. Please try again.", 'error');
        } finally {
            clearMessage();
            setIsLoading(false);
        }
    };


    useEffect(() => {
        generatedImageUrls.forEach((url, index) => {
            if (url) {
                const img = new Image();
                img.onload = () => {
                    setImageDimensions({ width: img.width, height: img.height });
                };
                img.src = url;
            }
        });
    }, [generatedImageUrls]);

    const handleDownload = async (imageUrl, index) => {
        const isMobileView = window.innerWidth < 768;
        const canvas = isMobileView ? mobileCanvasRefs.current[index] : desktopCanvasRefs.current[index];
        downloadImage(imageUrl, index, canvas);
    };

    const downloadImage = async (imageUrl, index, canvas) => {
        try {
            if (!canvas) return;

            // Add watermark
            const ctx = canvas.getContext('2d');
            ctx.fillStyle = 'rgba(255, 255, 255, 0.3)';
            ctx.font = '16px Arial';
            ctx.textAlign = 'right';
            ctx.textBaseline = 'bottom';
            ctx.fillText('ai.devsaura.com', canvas.width - 10, canvas.height - 10);

            const blob = await new Promise(resolve => canvas.toBlob(resolve, 'image/jpeg'));
            const url = window.URL.createObjectURL(blob);

            const extractFilename = (prompt, index) => {
                const words = prompt.trim().split(/\s+/);
                const filenameBase = words.slice(0, 6).join('_') || 'image';
                const safeBase = filenameBase.length > 45 ? filenameBase.slice(0, 45) : filenameBase;
                const sanitizedBase = safeBase.replace(/[<>:"/\\|?*]/g, '_');
                return `${sanitizedBase}_${index + 1}.jpg`;
            };

            const filename = extractFilename(formData.prompt, index);

            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Download error:', error);
            setMessage("An error occurred while downloading the image.", 'error');
        }
    };

    return (
        <div className="imgen-image-generator margin">
            {isLoggedIn() && <AdultContentWarning isVisible={adultImageCount >= 30} />}
            <p className='imgen-credit'>powered by
                <span><a href="https://groq.com/groqcloud/" target='_blank' rel='noreferrer noopener'>GroqCloud</a></span>
                &
                <span><a href="https://pollinations.ai" target='_blank' rel='noreferrer noopener'>Pollinations AI</a></span>
            </p>
            <MessageDisplay />

            {/* Mobile View */}
            <div className="imgen-mobile-view">
                <div className="imgen-mobile-tabs">
                    <button
                        onClick={() => setMobileView('prompt')}
                        className={`imgen-tab-button ${mobileView === 'prompt' ? 'active' : ''}`}
                    >
                        Prompt
                    </button>
                    <button
                        onClick={() => setMobileView('settings')}
                        className={`imgen-tab-button ${mobileView === 'settings' ? 'active' : ''}`}
                    >
                        Settings
                    </button>
                </div>
                <div className="imgen-mobile-content">
                    <form onSubmit={handleSubmit}>
                        {mobileView === 'prompt' ? (
                            <PromptSection
                                formData={formData}
                                wordCount={wordCount}
                                isLoading={isLoading}
                                handleChange={handleChange}
                                generateRandomSeed={generateRandomSeed}
                                handleImprovePrompt={handleImprovePrompt}
                                handleRandomPrompt={handleRandomPrompt}
                                setIsPromptValid={setIsPromptValid}
                            />
                        ) : (
                            <SettingsPanel
                                formData={formData}
                                handleChange={handleChange}
                                fieldOptions={fieldOptions}
                                dimensionPresets={dimensionPresets}
                            />
                        )}
                    </form>
                </div>
            </div>

            {/* Desktop View */}
            <div className="imgen-desktop-view">
                {/* Left Sidebar */}
                <div className="imgen-sidebar left-sidebar">
                    <form onSubmit={handleSubmit}>
                        <PromptSection
                            formData={formData}
                            wordCount={wordCount}
                            isLoading={isLoading}
                            handleChange={handleChange}
                            generateRandomSeed={generateRandomSeed}
                            handleImprovePrompt={handleImprovePrompt}
                            handleRandomPrompt={handleRandomPrompt}
                            setIsPromptValid={setIsPromptValid}
                        />
                    </form>
                </div>

                {/* Right Sidebar */}
                <div className="imgen-sidebar right-sidebar">
                    <SettingsPanel
                        formData={formData}
                        handleChange={handleChange}
                        fieldOptions={fieldOptions}
                        dimensionPresets={dimensionPresets}
                    />
                </div>

                {/* Center Content */}
                <div className="imgen-main-content">
                    <GeneratedImage
                        isLoading={isLoading}
                        generatedImageUrls={generatedImageUrls}
                        imageDimensions={imageDimensions}
                        canvasRefs={desktopCanvasRefs}
                        handleDownload={handleDownload}
                        isMobileView={false}
                    />
                </div>
            </div>

            {/* Mobile Generated Image and Buttons */}
            <div className="imgen-mobile-generated-image">
                <GeneratedImage
                    isLoading={isLoading}
                    generatedImageUrls={generatedImageUrls}
                    imageDimensions={imageDimensions}
                    canvasRefs={mobileCanvasRefs}
                    handleDownload={handleDownload}
                    isMobileView={true}
                />
            </div>
        </div>
    );
};

export default ImageGenerator;