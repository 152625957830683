import React from 'react';
import './ContentGeneration.css';
import { Link } from 'react-router-dom';

const ContentGeneration = () => {
    return (
        <div className="icgc-container">
            <div className="icgc-left-section">
                <h1 className="icgc-heading big-h">Read, Create and Edit</h1>
                <p className="icgc-description landing-p">
                    Transform your ideas into compelling content with our advanced Content Generation tool.
                    Perfect for creating blog posts, articles, product descriptions, or any written content
                    you need, our AI-powered system ensures high-quality, engaging results every time.
                </p>
                <div className="icgc-button">
                    <Link to="/generate-content">Create</Link>
                </div>
            </div>
            <div className="icgc-right-section">
                <div className="icgc-images-container">
                    <img src="../images/landing/reading.png" alt="Content Sample 1" className="icgc-image" />
                    <img src="../images/landing/generating.png" alt="Content Sample 2" className="icgc-image" />
                    <img src="../images/landing/editing.png" alt="Content Sample 3" className="icgc-image" />
                </div>
            </div>
        </div>
    );
};

export default ContentGeneration;