// ImageSettings.js
import React from 'react';
import { X, Loader, Check, Clipboard } from 'lucide-react';

export const ImageSettings = ({
    title,
    isDesign,
    showSettings,
    settingsData,
    setShowSettings,
    handleCopyClickInSettings,
    buttonStates,
    imageId
}) => {
    if (!showSettings || !settingsData) return null;

    return (
        <div
            className="ic-settings-overlay"
            onClick={(e) => {
                if (e.target.className === 'ic-settings-overlay') {
                    setShowSettings(false);
                }
            }}
        >
            <div className="ic-settings-content">
                <div className="ic-settings-header">
                    <h3>Image Settings</h3>
                    <button
                        className="ic-action-button"
                        onClick={() => setShowSettings(false)}
                    >
                        <X size={20} className="ic-action-icon" />
                    </button>
                </div>
                <div className="ic-settings-body">
                    <div className="ic-settings-item">
                        <span className="ic-settings-label">Width:</span>
                        <span>{settingsData.width}</span>
                    </div>
                    <div className="ic-settings-item">
                        <span className="ic-settings-label">Height:</span>
                        <span>{settingsData.height}</span>
                    </div>
                    <div className="ic-settings-item">
                        <span className="ic-settings-label">Model:</span>
                        <span>{settingsData.model}</span>
                    </div>
                    <div className="ic-settings-item">
                        <span className="ic-settings-label">Seed:</span>
                        <span>{settingsData.seed}</span>
                    </div>
                    {!isDesign ? (
                        <>
                            <div className="ic-settings-item">
                                <span className="ic-settings-label">Prompt:</span>
                                <span className="ic-settings-prompt">{settingsData.prompt}</span>
                            </div>
                            <button
                                onClick={handleCopyClickInSettings}
                                className="ic-action-button ic-copy-prompt"
                                title="Copy Prompt"
                                disabled={buttonStates[imageId]?.copy === 'loading'}
                            >
                                {buttonStates[imageId]?.copy === 'loading' ? (
                                    <><Loader size={20} className="ic-action-icon ic-animate-spin" /><p>Copying..</p></>
                                ) : buttonStates[imageId]?.copy === 'success' ? (
                                    <><Check size={20} className="ic-action-icon" /><p>Copied!</p></>
                                ) : (
                                    <><Clipboard size={20} className="ic-action-icon" /><p>Copy Prompt</p></>
                                )}
                            </button>
                        </>
                    ) : (
                        <div className="ic-settings-item">
                            <span className="ic-settings-label">Made in:</span>
                            <span className="ic-settings-prompt">{title.replace("Made in ", "")}</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};