import React, { useEffect, useState } from 'react';
import '../css/RandomLoader.css';
import '../css/RandomLoaderMetronome.css';

const loaderStyles = [

  // Metronome Loader
  () => (
    <div className="lp-metronome-container">
      {[...Array(36)].map((_, i) => (
        <div key={i} className={`lp-baton-${i}`}>
          <div className="lp-metronome">
            <div className="lp-baton"></div>
          </div>
        </div>
      ))}
    </div>
  ),
];

const RandomLoader = ({ className = "" }) => {
  const [LoaderComponent, setLoaderComponent] = useState(() => loaderStyles[0]);

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * loaderStyles.length);
    setLoaderComponent(() => loaderStyles[randomIndex]);
  }, []);

  return (
    <div className={`lp-loader-container ${className}`}>
      <LoaderComponent />
    </div>
  );
};

export default RandomLoader;