import Cookies from 'js-cookie';

export const setCookie = (name, value, days) => {
  Cookies.set(name, value, { expires: days });
};

export const getCookie = (name) => {
  return Cookies.get(name);
};

export const removeCookie = (name) => {
  Cookies.remove(name);
};

export const incrementImageGenerationCount = () => {
  const count = parseInt(getCookie('igc') || '0');
  const newCount = count + 1;
  setCookie('igc', newCount, 1); 
  return newCount;
};

// igc = image_generation_count

export const getImageGenerationCount = () => {
  return parseInt(getCookie('igc') || '0');
};

export const isImageGenerationLimited = () => {
  const count = getImageGenerationCount();
  return count >= 2 && !isLoggedIn();
};

export const isLoggedIn = () => {
  return localStorage.getItem('token') !== null;
};