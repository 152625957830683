import React, { useState, useRef, useEffect } from 'react';
import '../css/ContentEditor.css';

const ContentEditor = ({ content, onSave, onCancel, maxLength = 5192 }) => {
  const [editedContent, setEditedContent] = useState(content.content);
  const [error, setError] = useState('');
  const editorRef = useRef(null);

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.textContent = editedContent;
    }
  }, [editedContent]);

  const handleInput = () => {
    if (editorRef.current) {
      const newContent = editorRef.current.textContent;
      if (newContent.length > maxLength) {
        setError(`Content exceeds maximum length of ${maxLength} characters`);
        editorRef.current.textContent = newContent.slice(0, maxLength);
      } else {
        setError('');
        setEditedContent(newContent);
      }
    }
  };

  const sanitizeMarkdown = (markdown) => {
    // Regex to match image.pollinations.ai links
    const pollinationsRegex = /!\[.*?\]\(https:\/\/image\.pollinations\.ai\/[^)]+\)/g;

    // Find all pollinations links
    const pollinationsLinks = markdown.match(pollinationsRegex) || [];

    // Replace pollinations links with placeholders
    let placeholderIndex = 0;
    const withPlaceholders = markdown.replace(pollinationsRegex, () => `__POLLINATIONS_PLACEHOLDER_${placeholderIndex++}__`);

    // Remove any HTML tags
    const noHtml = withPlaceholders.replace(/<[^>]*>/g, '');

    // Escape special characters
    const escaped = noHtml
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;');

    // Replace placeholders with original pollinations links
    let finalContent = escaped;
    pollinationsLinks.forEach((link, index) => {
      finalContent = finalContent.replace(`__POLLINATIONS_PLACEHOLDER_${index}__`, link);
    });

    return finalContent;
  };

  const handleSave = () => {
    const sanitizedContent = sanitizeMarkdown(editedContent);
    onSave(sanitizedContent);
  };

  const applyFormatting = (format) => {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const selectedText = range.toString();

    if (selectedText) {
      let formattedText;
      switch (format) {
        case 'bold':
          formattedText = `**${selectedText}**`;
          break;
        case 'italic':
          formattedText = `*${selectedText}*`;
          break;
        case 'heading':
          formattedText = `\n## ${selectedText}\n`;
          break;
        default:
          formattedText = selectedText;
      }

      const newNode = document.createTextNode(formattedText);
      range.deleteContents();
      range.insertNode(newNode);
      handleInput();
    }
  };

  return (
    <div className="ce-editor-container">
      <div className="ce-editor-toolbar">
        <div className="ce-editor-buttons">
          <button className="ce-toolbar-button" onClick={() => applyFormatting('bold')}>B</button>
          <button className="ce-toolbar-button" onClick={() => applyFormatting('italic')}>I</button>
          <button className="ce-toolbar-button" onClick={() => applyFormatting('heading')}>H</button>
        </div>
        <div className="ce-editor-buttons">
          <button className="ce-editor-button ce-save-button" onClick={handleSave}>Save</button>
          <button className="ce-editor-button ce-cancel-button" onClick={onCancel}>Cancel</button>
        </div>
      </div>
      <pre
        ref={editorRef}
        className="ce-editor-content"
        contentEditable={true}
        onInput={handleInput}
        style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}
      />
      {error && <div className="ce-error-message">{error}</div>}
    </div>
  );
};

export default ContentEditor;