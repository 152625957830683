import React, { useState, useEffect } from 'react';
import { authenticatedApiCall } from '../../utils/authenticatedApiCall';
import useMessage from '../../hooks/useNotify';
import '../../css/NotificationsTab.css';

const NotificationsTab = () => {
    const [notifications, setNotifications] = useState([]);
    const [newNotification, setNewNotification] = useState({ title: '', message: '', type: 'info', action: '', target_users: [] });
    const [isLoading, setIsLoading] = useState(true);
    const { MessageDisplay, setMessage } = useMessage();

    useEffect(() => {
        fetchNotifications();
        // eslint-disable-next-line
    }, []);

    const fetchNotifications = async () => {
        setIsLoading(true);
        try {
            const response = await authenticatedApiCall('/admin/notifications', 'GET');
            if (response && Array.isArray(response.notifications)) {
                setNotifications(response.notifications);
            } else {
                throw new Error('Invalid response format');
            }
        } catch (error) {
            console.error('Error fetching notifications:', error);
            setMessage('Failed to fetch notifications', 'error');
        } finally {
            setIsLoading(false);
        }
    };

    const handleInputChange = (e) => {
        if (e.target.name === 'target_users') {
            setNewNotification({ ...newNotification, target_users: e.target.value.split(',').map(user => user.trim()) });
        } else {
            setNewNotification({ ...newNotification, [e.target.name]: e.target.value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Create a new object with only the necessary properties
            const dataToSend = {
                title: newNotification.title,
                message: newNotification.message,
                type: newNotification.type,
                action: newNotification.action,
                // Only include target_users if it's not an empty array
                ...(newNotification.target_users.length > 0 ? { target_users: newNotification.target_users } : {})
            };
            const response = await authenticatedApiCall('/admin/notifications', 'POST', dataToSend);
            if (response && response.notification) {
                setNewNotification({ title: '', message: '', type: 'info', action: '', target_users: [] });
                setNotifications(prevNotifications => [...prevNotifications, response.notification]);
                setMessage('Notification created successfully', 'success');
            } else {
                throw new Error('Invalid response format');
            }
        } catch (error) {
            console.error('Error creating notification:', error);
            setMessage('Failed to create notification', 'error');
        }
    };

    const handleDelete = async (id) => {
        try {
            await authenticatedApiCall(`/admin/notifications/${id}`, 'DELETE');
            setNotifications(prevNotifications => prevNotifications.filter(notification => notification.id !== id));
            setMessage('Notification deleted successfully', 'success');
        } catch (error) {
            console.error('Error deleting notification:', error);
            setMessage('Failed to delete notification', 'error');
        }
    };

    const handleResend = async (id) => {
        try {
            await authenticatedApiCall(`/admin/notifications/${id}/resend`, 'POST');
            setMessage('Notification resent successfully', 'success');
        } catch (error) {
            console.error('Error resending notification:', error);
            setMessage('Failed to resend notification', 'error');
        }
    };

    if (isLoading) {
        return <div>Loading notifications...</div>;
    }

    return (
        <div className="nt-notifications-tab">
            <MessageDisplay />
            <h2>Manage Notifications</h2>
            <form className="nt-form" onSubmit={handleSubmit}>
                <input
                    className="nt-input"
                    type="text"
                    name="title"
                    value={newNotification.title}
                    onChange={handleInputChange}
                    placeholder="Notification Title"
                    required
                />
                <textarea
                    className="nt-textarea"
                    name="message"
                    value={newNotification.message}
                    onChange={handleInputChange}
                    placeholder="Notification Message"
                    required
                />
                <select
                    className="nt-select"
                    name="type"
                    value={newNotification.type}
                    onChange={handleInputChange}
                >
                    <option value="info">Info</option>
                    <option value="warning">Warning</option>
                    <option value="promotion">Promotion</option>
                    <option value="instagram">Instagram</option>
                    <option value="contest">Contest</option>
                    <option value="community">Community</option>
                </select>
                <input
                    className="nt-input"
                    type="text"
                    name="action"
                    value={newNotification.action}
                    onChange={handleInputChange}
                    placeholder="Action URL (optional)"
                />
                <input
                    className="nt-input"
                    type="text"
                    name="target_users"
                    value={newNotification.target_users.join(', ')}
                    onChange={handleInputChange}
                    placeholder="Target Users (comma-separated)"
                />
                <button className="nt-button" type="submit">Create Notification</button>
            </form>
            <ul className="nt-notifications-list">
                {notifications.map((notification) => (
                    <li key={notification.id} className="nt-notification-item">
                        <div className="nt-notification-content">
                            <h3 className="nt-notification-title">{notification.title || 'No Title'}</h3>
                            <p className="nt-notification-message">{notification.message || 'No Message'}</p>
                            <span className={`nt-notification-type nt-type-${notification.type || 'info'}`}>
                                {notification.type || 'info'}
                            </span>
                            {notification.action && (
                                <a
                                    className="nt-action-link"
                                    href={notification.action}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Action Link
                                </a>
                            )}
                            {notification.target_users && notification.target_users.length > 0 && (
                                <div className="nt-opened-by">
                                    <strong>Target Users:</strong>
                                    <ul className="nt-read-by-list">
                                        {notification.target_users.map((user, index) => (
                                            <li key={index} className="nt-read-by-item">{user}</li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            <div className="nt-opened-by">
                                <strong>Opened by:</strong>
                                <ul className="nt-read-by-list">
                                    {notification.opened_by && notification.opened_by.map((user, index) => (
                                        <li key={index} className="nt-read-by-item">{user}</li>
                                    ))}
                                </ul>
                            </div>
                            <div className="nt-closed-by">
                                <strong>Closed by:</strong>
                                <ul className="nt-read-by-list">
                                    {notification.closed_by && notification.closed_by.map((user, index) => (
                                        <li key={index} className="nt-read-by-item">{user}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="nt-action-buttons">
                            <button
                                className="nt-resend-button"
                                onClick={() => handleResend(notification.id)}
                            >
                                Resend
                            </button>
                            <button
                                className="nt-delete-button"
                                onClick={() => handleDelete(notification.id)}
                            >
                                Delete
                            </button>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default NotificationsTab;