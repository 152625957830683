import React from 'react';
import './ImageGeneration.css';
import { Link } from 'react-router-dom';

const ImageGeneration = () => {
    return (
        <div className="igh-container">
            <div className="igh-left-section">
                <div className="igh-images-container">
                    <Link to={"/image/67191f257c4e61684b4e8802"} className="igh-image-link">
                        <img src="../images/landing/Flux-Style_Student_Residence_Hall.jpg" alt="Image-1" className="igh-image" />
                    </Link>
                    <Link to={"/image/6723c221b3c8f3aa11cfede0"} className="igh-image-link">
                        <img src="../images/landing/The_Fallen_Icon.jpg" alt="Image-2" className="igh-image" />
                    </Link>
                    <Link to={"/image/6727055c2a9cfbed3fe180ad"} className="igh-image-link">
                        <img src="../images/landing/The_ornate_bird.jpg" alt="Image-3" className="igh-image" />
                    </Link>
                </div>
            </div>
            <div className="igh-right-section">
                <h1 className="igh-heading big-h">Generate, Interact and Explore</h1>
                <p className="igh-description landing-p">
                    Discover the power of our Image Generation tool, designed to bring your ideas to life with stunning visuals.
                    Whether you're creating social media content, marketing materials, or simply exploring your creativity,
                    our tool provides a seamless experience.
                </p>
                <div className="igh-button">
                    <Link to={"/generate-image"}>Generate</Link>
                </div>
            </div>
        </div>
    );
};

export default ImageGeneration;
