import React from 'react';
import { Sun, Moon, Sparkles, Leaf, Cloud, Flame } from 'lucide-react';
import '../../css/ThemeSwitcher.css'

const ThemeButton = ({ theme, currentTheme, onClick, icon: Icon, label }) => (
  <button
    onClick={() => onClick(theme)}
    className={`theme-button ${currentTheme === theme ? 'active' : ''}`}
  >
    <Icon size={20} className={currentTheme === theme ? 'text-primary-brand-color-bright' : ''} />
    <span>{label}</span>
  </button>
);

const ThemeSwitcher = ({ currentTheme, onThemeChange }) => {
  const themes = [
    { id: 'light', icon: Sun, label: 'Light' },
    { id: 'dark', icon: Moon, label: 'Dark' },
    { id: 'futuristic-dark', icon: Sparkles, label: 'Futuristic' },
    { id: 'nature', icon: Leaf, label: 'Nature' },
    { id: 'ocean', icon: Cloud, label: 'Ocean' },
    { id: 'sunset', icon: Flame, label: 'Sunset' }
  ];

  return (
    <div className="theme-switcher">
      {themes.map(theme => (
        <ThemeButton
          key={theme.id}
          theme={theme.id}
          currentTheme={currentTheme}
          onClick={onThemeChange}
          icon={theme.icon}
          label={theme.label}
        />
      ))}
    </div>
  );
};

export default ThemeSwitcher;