// components/ImageGenerator/DimensionControls.js
import React from 'react';

const DimensionControls = ({ formData, handleChange, dimensionPresets }) => {
  return (
    <div className="imgen-dimension-controls">
      <select
        name="dimensionPreset"
        value={formData.dimensionPreset}
        onChange={handleChange}
        className="imgen-settings-select"
      >
        {Object.entries(dimensionPresets).map(([key, preset]) => (
          <option key={key} value={key}>
            {preset.label}
          </option>
        ))}
      </select>

      {formData.dimensionPreset === 'custom' && (
        <div className="imgen-dimension-inputs">
          <input
            type="number"
            name="width"
            value={formData.width}
            onChange={handleChange}
            placeholder="Width (350 - 2800)"
            className="imgen-dimension-input"
          />
          <span>X</span>
          <input
            type="number"
            name="height"
            value={formData.height}
            onChange={handleChange}
            placeholder="Height (350 - 2800)"
            className="imgen-dimension-input"
          />
        </div>
      )}
    </div>
  );
};

export default DimensionControls;