import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { FaGithub } from 'react-icons/fa';
import '../../css/GoogleSignIn.css';
import { isImageGenerationLimited } from '../../utils/cookies';
import { useDocumentHead } from '../../hooks/useDocumentHead';

const API_URL = process.env.REACT_APP_API;
// const API_URL = "http://localhost:5000";

const SignIn = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showImageGenerationReason, setShowImageGenerationReason] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useDocumentHead(
    'Account - Sign In | AI Tools',
    'Create an account or log in to access AI Tools. Start generating unique images, content, and more with easy signup and secure login options.'
  );

  useEffect(() => {
    setShowImageGenerationReason(isImageGenerationLimited() && location.state?.from === '/generate-image');
  }, [location]);

  useEffect(() => {
    const loadGoogleScript = () => {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
      script.onload = initializeGoogleLogin;
    };
    loadGoogleScript();
    // eslint-disable-next-line
  }, []);

  const initializeGoogleLogin = () => {
    window.google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: handleGoogleLogin
    });
    window.google.accounts.id.renderButton(
      document.getElementById('google-login-button'),
      { theme: 'filled_blue', size: 'large', shape: 'pill', text: 'continue_with' }
    );
  };

  const handleGoogleLogin = async (response) => {
    try {
      const res = await axios.post(`${API_URL}/google-login`, {
        token: response.credential
      }, {
        headers: {
          'Content-Type': 'application/json',
          'X-Key': process.env.REACT_APP_KEY,
        }
      });
      const { token, user } = res.data;
      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(user));
      navigate(location.state?.from || '/');
    } catch (error) {
      handleLoginError(error);
    }
  };

  const handleGithubLogin = () => {
    const githubClientId = process.env.REACT_APP_GITHUB_CLIENT_ID;
    const redirectUri = `${window.location.origin}/github-callback`;
    const scope = 'user:email';
    window.location.href = `https://github.com/login/oauth/authorize?client_id=${githubClientId}&redirect_uri=${redirectUri}&scope=${scope}`;
  };

  const handleLoginError = (error) => {
    console.error('Error during login:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
      if (error.response.status === 403) {
        setErrorMessage(error.response.data.error);
      } else {
        setErrorMessage('An unexpected error occurred. Please try again later.');
      }
    }
  };

  return (
    <div className="gs-container margin">
      <div className="gs-content">
        <div className="gs-logo-container">
          <img src="/images/fav-da.png" alt="Logo" className="gs-logo" />
        </div>
        <h1 className="gs-title">Welcome to AI Tools</h1>
        {!showImageGenerationReason && !errorMessage && (
          <p className="gs-subtitle">Sign in to access all features</p>
        )}
        {showImageGenerationReason && !errorMessage && (
          <p className="gs-subtitle">
            😊 Enjoying the image creation? Simply log in to keep generating for free or try our other tools!
          </p>
        )}
        {errorMessage && (
          <div className="gs-error-container">
            <p className="gs-error-message">{errorMessage}</p>
            <Link to="/terms" className="gs-error-button">View Terms of Service</Link>
          </div>
        )}
        <div className="gs-benefits">
          <h2>Why Create an Account?</h2>
          <ul>
            <li><AiOutlineCheckCircle /> Personalized experience</li>
            <li><AiOutlineCheckCircle /> Unlimited generations</li>
            <li><AiOutlineCheckCircle /> Save your content</li>
            <li><AiOutlineCheckCircle /> Instant downloads</li>
            <li><AiOutlineCheckCircle /> Follow artists</li>
          </ul>
        </div>
        <div className="gs-signin-buttons">
          <div id="google-login-button"></div>
          <button
            onClick={handleGithubLogin}
            className="github-login-button"
          >
            <FaGithub className="github-icon" />
            Continue with GitHub
          </button>
        </div>
        <p className="gs-terms">By signing in, you agree to our <Link to="/terms">Terms of Service</Link> and <Link to="/privacy">Privacy Policy</Link></p>
      </div>
    </div>
  );
};

export default SignIn;