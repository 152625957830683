// components/ImageGenerator/constants.js
export const fieldOptions = {
    model: ["flux", "turbo"],
    artStyle: ['realistic', 'anime', '3d', 'abstract', 'surreal', 'cartoonish', 'minimalist'],
    perspective: ["bird's eye view", 'first-person', 'wide angle', 'close-up'],
    theme: ['futuristic', 'nature', 'urban', 'fantasy', 'historical'],
    lighting: ['soft lighting', 'dramatic lighting', 'natural lighting', 'neon lighting'],
    colorPalette: ['warm colors', 'cool colors', 'monochromatic', 'pastel colors'],
    composition: ['central subject', 'rule of thirds', 'leading lines', 'asymmetry'],
    mood: ['serene', 'energetic', 'nostalgic', 'dramatic']
};

export const dimensionPresets = {
    'default': { width: 1080, height: 1900, label: 'Default 1080 x 1900' },
    'instagram-square': { width: 1080, height: 1080, label: 'Instagram Square 1080 x 1080' },
    'instagram-portrait': { width: 1080, height: 1400, label: 'Instagram Portrait 1080 x 1400' },
    'instagram-landscape': { width: 1080, height: 566, label: 'Instagram Landscape 1080 x 566' },
    'youtube-thumbnail': { width: 1280, height: 720, label: 'YouTube Thumbnail 1280 x 720' },
    'facebook-post': { width: 1200, height: 630, label: 'Facebook Post 1200 x 630' },
    'twitter-post': { width: 1600, height: 900, label: 'Twitter Post 1600 x 900' },
    'linkedin-post': { width: 1200, height: 627, label: 'LinkedIn Post 1200 x 627' },
    'logo': { width: 500, height: 500, label: 'Logo 500 x 500' },
    'desktop-wallpaper': { width: 1920, height: 1080, label: 'Desktop Wallpaper 1920 x 1080' },
    'mobile-wallpaper': { width: 1080, height: 1920, label: 'Mobile Wallpaper 1080 x 1920' },
    'pinterest-pin': { width: 1000, height: 1500, label: 'Pinterest Pin 1000 x 1500' },
    'custom': { width: 1080, height: 1080, label: 'Custom Dimensions' }
};