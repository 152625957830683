import React, { useEffect, useRef, useState } from 'react';
import './About.css';
import Footer from '../Components/Footer';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { isLoggedIn } from '../utils/cookies';
import { useDocumentHead } from '../hooks/useDocumentHead';
import ImageGeneration from './ImageGeneration';
import ContentGeneration from './ContentGeneration'
import ThemeShowcase from './ThemeShowcase';

const About = () => {
  const firstColumnRef = useRef(null);
  const secondColumnRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();

  useDocumentHead(
    'AI Tools | Devsaura - Explore Image and Content Generator',
    'Welcome to AI Tools by Devsaura, your destination for free, advanced AI-powered tools. Generate unique images, blogs, stories, and more with ease. Discover limitless creativity and productivity tools tailored to your needs.'
  );

  const aiTools = [
    "Generate Walpapers",
    "Customize Profile",
    "Generate Images",
    "Improve Prompt",
    "Generate Logos",
    "Random Prompts",
    "Download Images",
    "Generate Content",
    "Explore Others Art",
    "Auto Save Images",
    "Follow Fav Artists"
  ];

  useEffect(() => {
    // Only navigate on first load if the user is logged in and session is new
    if (isLoggedIn() && !sessionStorage.getItem('createPageVisited')) {
      navigate('/create');
      sessionStorage.setItem('createPageVisited', 'true');
    }
  }, [navigate]);


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const firstColumn = firstColumnRef.current;
    const secondColumn = secondColumnRef.current;

    if (firstColumn && secondColumn) {
      const animateScroll = () => {
        if (isMobile) {
          if (firstColumn.scrollLeft >= firstColumn.scrollWidth / 2) {
            firstColumn.scrollLeft = 0;
          } else {
            firstColumn.scrollLeft += 1;
          }

          if (secondColumn.scrollLeft <= 0) {
            secondColumn.scrollLeft = secondColumn.scrollWidth / 2;
          } else {
            secondColumn.scrollLeft -= 0.4;
          }
        } else {
          if (firstColumn.scrollTop >= firstColumn.scrollHeight / 2) {
            firstColumn.scrollTop = 0;
          } else {
            firstColumn.scrollTop += 0.9;
          }

          if (secondColumn.scrollTop <= 0) {
            secondColumn.scrollTop = secondColumn.scrollHeight / 2;
          } else {
            secondColumn.scrollTop -= 0.4;
          }
        }

        requestAnimationFrame(animateScroll);
      };

      requestAnimationFrame(animateScroll);
    }
  }, [isMobile]);


  return (
    <>
      <div className='home about-home'>
        <div className="left center">
          <h1 className='big-h'>Creative AI Tools by  <span>devs</span><span>aura</span><span>.</span></h1>
          <p className='landing-p'>Experience a unique platform that blends powerful image generation
            and content creation tools with social features like profiles and feed. Easily
            create and share visuals, write scripts or stories. Whether it’s designing logos,
            wallpapers, or thumbnails. This is your space to collaborate, spark creativity,
            and turn your ideas into reality.</p>
        </div>
        <div className={`right ${isMobile ? 'mobile' : ''}`}>
          <div className="scroll-column first" ref={firstColumnRef}>
            {[...aiTools, ...aiTools].map((tool, index) => (
              <div key={`first-${index}`} className="item center">
                <p>{tool}</p>
              </div>
            ))}
          </div>
          <div className="scroll-column second" ref={secondColumnRef}>
            {[...aiTools, ...aiTools].map((tool, index) => (
              <div key={`second-${index}`} className="item center">
                <p>{tool}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="home-buttons">
        <Link to={"/create"}>Create Now</Link>
        <Link to={"/home"}>Explore</Link>
      </div>
      <ThemeShowcase />
      <ImageGeneration />
      <ContentGeneration />
      <Footer />
    </>
  );
};

export default About;