import React from 'react';
import DesignGallery from './Design/DesignGallery';

const ThumbnailGenerator = () => {
  return (
    <div className='margin'>
      <DesignGallery
        type="thumbnail"
        title="Thumbnail Designs Gallery"
        apiEndpoint="/thumbnail-designs"
        cardHeight="150px" 
        itemsPerRow={{ default: '250px', mobile: '200px' }} 
        designViewerProps={{
          inputPlaceholder: "Describe your thumbnail modifications..." 
        }}
      />
    </div>
  );
};

export default ThumbnailGenerator;