// Header.js
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { LayoutGrid, Search, User, BrainCircuit, BookOpen } from 'lucide-react';
import '../css/Header.css';
import { FaInfo, FaQuestion } from 'react-icons/fa';
import ComparisonPopup from './ComparisonPopup';
import { authenticatedApiCall } from '../utils/authenticatedApiCall';

const Header = () => {
  const isLoggedIn = !!localStorage.getItem('token');
  const location = useLocation();
  const [hasNewNotification, setHasNewNotification] = useState(false);

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme && ['light', 'dark', 'futuristic-dark', 'nature', 'ocean', 'sunset'].includes(savedTheme)) {
      document.documentElement.setAttribute('data-theme', savedTheme);
    } else {
      localStorage.setItem('theme', 'light');
      document.documentElement.setAttribute('data-theme', 'light');
    }
  }, []);

  useEffect(() => {
    const setViewHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    const updateNavHeight = () => {
      const navHeight = window.innerHeight - window.visualViewport.height + 60;
      document.documentElement.style.setProperty('--mobile-nav-height', `${navHeight}px`);
    };

    setViewHeight();
    updateNavHeight();

    window.addEventListener('resize', setViewHeight);
    window.visualViewport.addEventListener('resize', updateNavHeight);

    return () => {
      window.removeEventListener('resize', setViewHeight);
      window.visualViewport.removeEventListener('resize', updateNavHeight);
    };
  }, []);

  useEffect(() => {
    if (hasNewNotification && location.pathname === '/profile') {
      setHasNewNotification(false);
    }
  }, [location.pathname, hasNewNotification]);

  useEffect(() => {
    let isMounted = true;
    let timeoutId;

    const updateLastActive = async () => {
      try {
        const response = await authenticatedApiCall('/update-last-active', 'POST');
        if (isMounted) {
          setHasNewNotification(response.new_notification);
        }
      } catch (error) {
        console.error('Error updating last active:', error);
      } finally {
        if (isMounted) {
          timeoutId = setTimeout(updateLastActive, 1850000);
        }
      }
    };

    if (isLoggedIn) {
      updateLastActive();
    }

    return () => {
      isMounted = false;
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isLoggedIn]);

  const NavItem = ({ to, icon: Icon, label }) => {
    const isActive = location.pathname === to;
    return (
      <Link
        to={to}
        className={`header-nav-item ${isActive ? 'active' : ''}`}
      >
        <div className="header-nav-item-container">
          <Icon size={24} />
          {hasNewNotification && to === '/profile' && (
            <div className="header-nav-notification-dot"></div>
          )}
        </div>
        <span className="header-nav-label">{label}</span>
      </Link>
    );
  };

  return (
    <>
      {!isLoggedIn && (
        <div className='header-contact-icons'>
          <Link to="/" className="header-contact-us" title="Contact Us">
            <FaInfo size={20} />
          </Link>
          <Link to="/docs" className="header-contact-us" title="Questions">
            <FaQuestion size={20} />
          </Link>
        </div>
      )}
      <nav className="header-header-nav">
        <div className="header-nav-container">
          <div className="header-nav-items">
            <NavItem to="/home" icon={LayoutGrid} label="Images" />
            <NavItem to="/content" icon={BookOpen} label="Content" />
            <NavItem to="/explore" icon={Search} label="Search" />
            <NavItem to="/create" icon={BrainCircuit} label="Create" />
            {isLoggedIn ? (
              <NavItem to="/profile" icon={User} label="Profile" />
            ) : (
              <NavItem to="/login" icon={User} label="Login" />
            )}
          </div>
        </div>
      </nav>
      <div className="header-header-spacer"></div>
      {isLoggedIn && (
        <ComparisonPopup />
      )}
    </>
  );
}

export default Header;