import { useEffect } from 'react';

export const useDocumentHead = (title, description, robots = null) => {
    useEffect(() => {
        // Save original values
        const originalTitle = document.title;
        const originalDescription = document.querySelector('meta[name="description"]')?.content;
        const originalRobots = document.querySelector('meta[name="robots"]')?.content;

        // Update title
        document.title = title;

        // Update or create description meta tag
        let metaDescription = document.querySelector('meta[name="description"]');
        if (!metaDescription) {
            metaDescription = document.createElement('meta');
            metaDescription.name = 'description';
            document.head.appendChild(metaDescription);
        }
        metaDescription.content = description;

        // Update or create robots meta tag if provided
        let metaRobots = document.querySelector('meta[name="robots"]');
        if (robots) {
            if (!metaRobots) {
                metaRobots = document.createElement('meta');
                metaRobots.name = 'robots';
                document.head.appendChild(metaRobots);
            }
            metaRobots.content = robots;
        }

        // Cleanup function
        return () => {
            document.title = originalTitle;
            
            if (metaDescription && originalDescription) {
                metaDescription.content = originalDescription;
            } else if (metaDescription) {
                metaDescription.remove();
            }

            if (metaRobots && originalRobots) {
                metaRobots.content = originalRobots;
            } else if (metaRobots && robots) {
                // Only remove if we added it
                metaRobots.remove();
            }
        };
    }, [title, description, robots]);
};