export const downloadImage = async (imageUrl, imageTitle) => {
  const img = new Image();
  img.crossOrigin = "Anonymous";

  await new Promise((resolve, reject) => {
    img.onload = resolve;
    img.onerror = reject;
    img.src = imageUrl;
  });

  const canvas = document.createElement('canvas');
  canvas.width = img.width;
  canvas.height = img.height;
  const ctx = canvas.getContext('2d');

  ctx.drawImage(img, 0, 0, img.width, img.height);

  // Calculate watermark size and position
  const minDimension = Math.min(img.width, img.height);
  const watermarkSize = Math.max(minDimension * 0.03, 12); // Minimum size of 12px
  const maxSize = 40; // Maximum size of 40px
  const fontSize = Math.min(watermarkSize, maxSize);

  const padding = fontSize * 0.1; // Padding from the corner
  const x = img.width - padding;
  const y = img.height - padding;

  // Add watermark
  ctx.fillStyle = 'rgba(255, 255, 255, 0.4)';
  ctx.font = `${fontSize}px Arial`;
  ctx.textAlign = 'right';
  ctx.textBaseline = 'bottom';
  ctx.fillText('ai.devsaura.com', x, y);

  const blob = await new Promise(resolve => canvas.toBlob(resolve, 'image/jpeg'));
  const url = window.URL.createObjectURL(blob);

  // Use the image title if available, otherwise fall back to extracting from URL
  const filename = imageTitle
    ? sanitizeFilename(imageTitle) + '.jpg'
    : extractFilename(imageUrl);

  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};

// Helper functions remain the same
const sanitizeFilename = (filename) => {
  return filename
    .replace(/[<>:"/\\|?*]/g, '_')
    .replace(/\s+/g, '_')
    .slice(0, 200);
};

export const extractFilename = (url) => {
  const prompt = extractPromptFromUrl(url);
  const words = prompt.trim().split(/\s+/);
  const filenameBase = words.slice(0, 8).join('_') || 'image';
  const safeBase = filenameBase.length > 45 ? filenameBase.slice(0, 45) : filenameBase;
  return sanitizeFilename(safeBase) + '.jpg';
};

export const extractPromptFromUrl = (url) => {
  try {
    const urlObj = new URL(url);
    const promptPath = urlObj.pathname.split('/prompt/')[1];
    return decodeURIComponent(promptPath);
  } catch (error) {
    console.error('Error extracting prompt from URL:', error);
    return '';
  }
};