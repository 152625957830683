import React, { useState, useEffect } from 'react';
import '../css/MessageDisplay.css'

const MessageDisplay = ({ message, type, duration = 3000 }) => {
  const [visible, setVisible] = useState(false);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    if (message) {
      setVisible(true);
      const newTimer = setTimeout(() => {
        setVisible(false);
      }, duration);
      setTimer(newTimer);
    } else {
      clearTimeout(timer);
      setVisible(false);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
     // eslint-disable-next-line
  }, [message, duration]);

  return visible ? (
    <div className={`message-display ${type}`}>
      {message}
    </div>
  ) : null;
};

export const useMessage = (initialDuration = 3000) => {
  const [messageState, setMessageState] = useState({ text: null, type: null });
  const [duration, setDuration] = useState(initialDuration);
  const [timer, setTimer] = useState(null);

  const setMessage = (text, type, customDuration) => {
    setMessageState({ text, type });
    if (customDuration) setDuration(customDuration);
    if (timer) {
      clearTimeout(timer);
    }
    const newTimer = setTimeout(() => {
      setMessageState({ text: null, type: null });
    }, customDuration || duration);
    setTimer(newTimer);
  };

  const clearMessage = () => {
    if (timer) {
      clearTimeout(timer);
    }
    setMessageState({ text: null, type: null });
  };

  return {
    MessageDisplay: () => (
      <MessageDisplay
        message={messageState.text}
        type={messageState.type}
        duration={duration}
      />
    ),
    setMessage,
    clearMessage,
  };
};

export default useMessage;