import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const AdminRoute = () => {
  const isAuthenticated = !!localStorage.getItem('token');

  const isAdmin = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    return user && user.is_admin;
  };

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return isAdmin() ? <Outlet /> : <Navigate to="/home" replace />;
};

export default AdminRoute;