// LikedUsersModal.js
import React from 'react';
import { Link } from 'react-router-dom';
import { User, X } from 'lucide-react';

export const LikedUsersModal = ({ showLikedUsers, setShowLikedUsers, likedUsers }) => {
    if (!showLikedUsers) return null;

    return (
        <div
            className="ic-settings-overlay"
            onClick={(e) => {
                if (e.target.className === 'ic-settings-overlay') {
                    setShowLikedUsers(false);
                }
            }}
        >
            <div className="ic-settings-content">
                <div className="ic-settings-header">
                    <h3>Liked by</h3>
                    <button
                        className="ic-action-button"
                        onClick={() => setShowLikedUsers(false)}
                    >
                        <X size={20} className="ic-action-icon" />
                    </button>
                </div>
                <div className="ic-settings-body">
                    {likedUsers.length > 0 ? (
                        <div className="ic-liked-users-list">
                            {likedUsers.map((user, index) => (
                                <Link
                                    key={index}
                                    to={`/profile/${user.username}`}
                                    className="ic-liked-user-item"
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <div className="ic-user-avatar-container">
                                        {user.imageUrl ? (
                                            <img
                                                src={user.imageUrl}
                                                alt={user.username}
                                                className="ic-user-avatar"
                                            />
                                        ) : (
                                            <div className="ic-avatar-placeholder">
                                                <User size={24} />
                                            </div>
                                        )}
                                    </div>
                                    <span className="ic-username">{user.username}</span>
                                </Link>
                            ))}
                        </div>
                    ) : (
                        <div className="ic-no-likes">No likes yet</div>
                    )}
                </div>
            </div>
        </div>
    );
};