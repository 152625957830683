const API_URL = process.env.REACT_APP_API;
// const API_URL = "http://localhost:5000";

export const authenticatedApiCall = async (endpoint, method = 'GET', body = null, isStreaming = false) => {
  const token = localStorage.getItem('token');
  const headers = {
    'Content-Type': 'application/json',
    'X-Key': process.env.REACT_APP_KEY,
  };

  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  const options = {
    method,
    headers,
  };

  const url = `${API_URL}${endpoint}`;

  if (body) {
    options.body = JSON.stringify(body);
  }

  try {
    const response = await fetch(url, options);

    if (!response.ok) {
      if (response.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      const errorData = await response.json();
      throw new Error(errorData.error || 'An error occurred');
    }

    if (isStreaming) {
      return response; // Return the response object for streaming
    } else {
      const data = await response.json();
      // Special handling for no new notifications
      if (endpoint === '/api/current-notification' && data.message === 'NO_NEW_NOTIFICATIONS') {
        return null;
      }
      return data;
    }
  } catch (error) {
    console.error('API call error:', error);
    throw error;
  }
};