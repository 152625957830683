// LogoGenerator.js
import React from 'react';
import DesignGallery from './Design/DesignGallery';

const LogoGenerator = () => {
  return (
    <div className='margin'>
      <DesignGallery
        type="logo"
        title="Logo Designs Gallery"
        apiEndpoint="/logo-designs"
        cardHeight="200px"
        itemsPerRow={{ default: '250px', mobile: '200px' }}
      />
    </div>
  );
};

export default LogoGenerator;