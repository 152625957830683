import { useState, useCallback, useEffect, useRef } from 'react';
import { authenticatedApiCall } from '../../utils/authenticatedApiCall';

const useLikeImage = (initialImages) => {
  const [images, setImages] = useState(initialImages);
  const [likeInProgress, setLikeInProgress] = useState({});
  const [error, setError] = useState(null);
  const imagesRef = useRef(initialImages);

  useEffect(() => {
    if (JSON.stringify(initialImages) !== JSON.stringify(imagesRef.current)) {
      setImages(initialImages);
      imagesRef.current = initialImages;
    }
  }, [initialImages]);

  const handleLike = useCallback(async (imageId) => {
    if (!imageId || likeInProgress[imageId]) {
      return { success: false, newLikeStatus: null };
    }

    setLikeInProgress(prev => ({ ...prev, [imageId]: true }));

    const imageIndex = images.findIndex(img => img.id === imageId);
    if (imageIndex === -1) {
      setLikeInProgress(prev => ({ ...prev, [imageId]: false }));
      return { success: false, newLikeStatus: null };
    }

    const currentImage = images[imageIndex];
    const currentLikeCount = currentImage.likeCount;
    const isCurrentlyLiked = currentImage.is_liked;
    const newLikeStatus = !isCurrentlyLiked;

    try {
      // Optimistically update UI
      setImages(prevImages => prevImages.map((img) =>
        img.id === imageId ? {
          ...img,
          likeCount: isCurrentlyLiked ? currentLikeCount - 1 : currentLikeCount + 1,
          is_liked: newLikeStatus
        } : img
      ));

      const response = await authenticatedApiCall(`/update-like-count/${imageId}`, 'POST', {
        increment: isCurrentlyLiked ? -1 : 1
      });

      if (!response.success) {
        throw new Error(response.error || 'Failed to update like count');
      }

      return { 
        success: true, 
        newLikeStatus,
        likeCount: isCurrentlyLiked ? currentLikeCount - 1 : currentLikeCount + 1
      };
    } catch (err) {
      console.error('Error updating like count:', err);
      setError(`Error updating like: ${err.message}`);

      // Revert the optimistic update
      setImages(prevImages => prevImages.map((img) =>
        img.id === imageId ? { ...img, likeCount: currentLikeCount, is_liked: isCurrentlyLiked } : img
      ));

      return { 
        success: false, 
        newLikeStatus: isCurrentlyLiked,
        likeCount: currentLikeCount
      };
    } finally {
      setLikeInProgress(prev => ({ ...prev, [imageId]: false }));
    }
  }, [images, likeInProgress]);

  return { images, setImages, handleLike, likeInProgress, error };
};

export default useLikeImage;