import React from 'react';
import { AlertTriangle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import '../../css/AdultContentWarning.css'

const AdultContentWarning = ({ isVisible }) => {
    const navigate = useNavigate();

    if (!isVisible) return null;

    return (
        <div className="acw-fixed acw-inset-0 acw-bg-red-500/90 acw-backdrop-blur-sm acw-z-50 acw-flex acw-items-center acw-justify-center acw-p-4">
            <div className="acw-bg-white acw-rounded-lg acw-p-6 acw-max-w-md acw-text-center acw-shadow-xl">
                <AlertTriangle className="acw-w-16 acw-h-16 acw-text-red-500 acw-mx-auto acw-mb-4" />
                <h2 className="acw-text-2xl acw-font-bold acw-text-gray-900 acw-mb-4">Review Content to Continue</h2>
                <p className="acw-text-gray-600 acw-mb-6">
                    It looks like your profile contains adult images. To help keep our community safe and welcoming for all users, please remove any adult content from your profile to continue creating new images.
                </p>
                <button
                    onClick={() => navigate('/profile', { state: { fromAdultWarning: true } })}
                    className="acw-bg-red-500 acw-text-white acw-px-6 acw-py-2 acw-rounded-lg acw-hover:bg-red-600 acw-transition-colors"
                >
                    Go to Profile
                </button>
            </div>
        </div>
    );
};

export default AdultContentWarning;
