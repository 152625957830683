import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import '../css/Tools.css'
import { useDocumentHead } from '../hooks/useDocumentHead'

// Utility function to highlight search term
const highlightText = (text, searchTerm) => {
    if (!searchTerm.trim()) return text;

    const regex = new RegExp(`(${searchTerm})`, 'gi');
    return text.split(regex).map((part, index) =>
        regex.test(part) ? <mark key={index} className="tools-highlight">{part}</mark> : part
    );
};

const Tools = () => {

    useDocumentHead(
        'Create with AI Tools - Explore All Our Features',
        'Discover all AI tools available on this page. From image generation to content creation, explore features designed to enhance your creativity and productivity.'
    );

    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchChange = useCallback((e) => {
        const newSearchTerm = e.target.value;
        setSearchTerm(newSearchTerm);
    }, []);

    useEffect(() => {
    }, [searchTerm]);

    // Define tools data using useMemo
    const toolsData = useMemo(() => [
        {
            link: "/generate-logo",
            imgPath: "/images/tools/logo.jpg",
            title: "Generate Logo",
            subtitle: "with prebuilt designs",
            description: "logo design AI-generated branding symbols graphic art customizable templates unique branding"
        },       
        {
            link: "/generate-thumbnail",
            imgPath: "/images/tools/thumbnail.jpg",
            title: "Generate Thumbnails",
            subtitle: "with prebuilt designs",
            description: "thumbnail design AI-generated branding symbols graphic art youtube templates unique branding"
        },       
        {
            link: "/generate-content",
            imgPath: "/images/tools/content.jpg",
            title: "Generate Content",
            subtitle: "with related ai visuals",
            description: "content generator creative writing narrative script youtube plot inspiration AI-generated"
        },
    ], []);

    // Filter tools based on search term using useMemo
    const filteredTools = useMemo(() => {
        if (!searchTerm.trim()) {
            return toolsData;
        }
        return toolsData.filter(tool =>
            tool.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            tool.description.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }, [searchTerm, toolsData]);

    return (
        <div>
            <div className='tools-tools-heading'>
                <h1 className='tools-tools-title'>All tools</h1>
                <input
                    type="text"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    placeholder="Search tools..."
                    className="tools-tools-search"
                />
            </div>
            <div className="tools-tools-container">
                <Link to="/generate-image" className="tools-featured-tool">
                    <img src="/images/tools/image-generation.jpeg" alt="generate_image" className="tools-featured-image" />
                    <div className="tools-featured-overlay">
                        <h3 className="tools-featured-title">Generate Image</h3>
                    </div>
                    <span className="tools-featured-tag">with ai prompt improvement</span>
                </Link>
                <div className="tools-tools-grid">
                    {filteredTools.map((tool, index) => (
                        <Link to={tool.link} key={index} className="tools-tool-card">
                            <img src={tool.imgPath} alt={tool.title} className="tools-tool-image" />
                            <div className="tools-tool-content">
                                <h3 className="tools-tool-title">{highlightText(tool.title, searchTerm)}</h3>
                                <span className="tools-tool-subtitle">{tool.subtitle}</span>
                            </div>
                        </Link>
                    ))}
                </div>
                {filteredTools.length === 0 && <p className="tools-no-results">No tools found matching your search.</p>}
            </div>
        </div>
    );
}

export default Tools;
