import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Trash2, Image, Heart, MoreHorizontal, MessageCircle } from 'lucide-react';
import LazyLoad from 'react-lazyload';
import '../css/ImageCard.css';
import { formatTimestamp } from '../utils/dateFormatter';
import { LikedUsersModal } from './ImageCard/LikedUserModal';
import { ImageSettings } from './ImageCard/ImageSettings';
import { MoreOptions } from './ImageCard/MoreOptions';
import CommentBox from './ImageCard/CommentBox';

const MoreOptionsContext = React.createContext();

export const MoreOptionsProvider = ({ children }) => {
    const [activeMoreOptions, setActiveMoreOptions] = useState(null);
    return (
        <MoreOptionsContext.Provider value={{ activeMoreOptions, setActiveMoreOptions }}>
            {children}
        </MoreOptionsContext.Provider>
    );
};

const ImageCard = React.memo(({
    image,
    isAdmin,
    onRemoveReportedStatus,
    onDeleteImage,
    index,
    selectedImageIndex,
    handleLike,
    copyPromptToClipboard,
    handleDownload,
    handleDelete,
    handleCopyMarkdownLink,
    handleReport,
    buttonStates,
    likeInProgress,
    isOwnProfile,
    isLikedImage,
    hideUserInfo,
    isMainImage,
    isToolGenerated,
    isShowingInContent,
    isOwnContentImages,
    isAdminView,
    handleShareClick,
    handleShowSettings,
    handleSetAsProfile,
    onToggleAdultStatus,
    isLoggedIn,
    isRealtedImage,
    handleGetLikedByUsers,
}) => {
    const navigate = useNavigate();
    const [imageLoaded, setImageLoaded] = useState(false);
    const [profileImageLoaded, setProfileImageLoaded] = useState(false);
    const [isMobile, setIsMobile] = useState(false); // Initialize as false
    const [is_liked, setIsLiked] = useState(image.is_liked);
    const [isLandscape, setIsLandscape] = useState(false);
    const [isHidden, setIsHidden] = useState(false);
    const [isBlurred, setIsBlurred] = useState(image.isAdult);
    const [showHeartAnimation, setShowHeartAnimation] = useState(false);
    const imageRef = useRef(null);
    const clickTimeoutRef = useRef(null);
    const clickCountRef = useRef(0);
    const [showMoreOptions, setShowMoreOptions] = useState(false);
    const moreOptionsRef = useRef(null);
    const [showSettings, setShowSettings] = useState(false);
    const [settingsData, setSettingsData] = useState(null);
    const [showLikedUsers, setShowLikedUsers] = useState(false);
    const [likedUsers, setLikedUsers] = useState([]);
    const [loadingLikes, setLoadingLikes] = useState(false);
    const [showComments, setShowComments] = useState(false);
    const currentUser = isLoggedIn ? JSON.parse(localStorage.getItem('user') || '{}') : null;

    const isLoggedInForCommments = localStorage.getItem('token') !== null;

    const { activeMoreOptions, setActiveMoreOptions } = React.useContext(MoreOptionsContext);

    const getPlaceholderColor = (id) => {
        const colorIndex = typeof id === 'string'
            ? Math.abs(id.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0)) % 8 + 1
            : (id % 8) + 1;
        return `color-${colorIndex}`;
    };

    // Detect mobile only once on mount
    useEffect(() => {
        const checkMobile = () => {
            // const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
            // const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
            // setIsMobile(window.innerWidth <= 768 || (isSafari && isIOS));
            setIsMobile(window.innerWidth <= 768);
        };

        checkMobile();
        const handleResize = () => {
            requestAnimationFrame(checkMobile);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Cleanup function to remove event listeners and reset states
    useEffect(() => {
        return () => {
            if (clickTimeoutRef.current) {
                clearTimeout(clickTimeoutRef.current);
            }
            setShowMoreOptions(false);
            setActiveMoreOptions(null);
        };
    }, [setActiveMoreOptions]);

    // Reset more options when active options change
    useEffect(() => {
        if (activeMoreOptions && activeMoreOptions !== image.id) {
            setShowMoreOptions(false);
        }
    }, [activeMoreOptions, image.id]);

    const handleSettingsClick = useCallback(async (e) => {
        e.stopPropagation();
        setShowSettings(true);
        const response = await handleShowSettings(image.imageUrl, image.id);
        if (!response?.success) {
            setShowSettings(false);
            return;
        }
        setSettingsData(response.settings);
    }, [handleShowSettings, image.imageUrl, image.id]);

    const handleMoreClick = useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();

        const newState = !showMoreOptions;
        setShowMoreOptions(newState);
        setActiveMoreOptions(newState ? image.id : null);

    }, [showMoreOptions, image.id, setActiveMoreOptions]);

    const handleCardClick = useCallback((e) => {
        e.preventDefault();
        clickCountRef.current += 1;

        if (clickTimeoutRef.current) {
            clearTimeout(clickTimeoutRef.current);
        }

        clickTimeoutRef.current = setTimeout(() => {
            if (clickCountRef.current === 1) {
                // Single click
                if (!isLoggedIn && isRealtedImage) {
                    navigate('/login');
                } else {
                    navigate(`/image/${image.id}`, { state: { image } });
                }
            } else if (clickCountRef.current === 2) {
                if (!is_liked && !likeInProgress[image.id]) {
                    handleLike(image.id, index);
                    setIsLiked(true);
                    setShowHeartAnimation(true);
                    setTimeout(() => setShowHeartAnimation(false), 1000);
                } else if (!isLoggedIn) {
                    navigate('/login');
                }
            }
            clickCountRef.current = 0;
        }, 300);
    }, [navigate, image, handleLike, index, is_liked, likeInProgress, isLoggedIn, isRealtedImage]);

    const handleLikeClick = useCallback((e) => {
        e.stopPropagation();
        if (!isLoggedIn && isRealtedImage) {
            navigate('/login');
            return;
        }
        handleLike(image.id, index);
        setIsLiked(!is_liked);
        if (!is_liked) {
            setShowHeartAnimation(true);
            setTimeout(() => setShowHeartAnimation(false), 1000);
        }
    }, [handleLike, image.id, index, is_liked, isLoggedIn, navigate, isRealtedImage]);

    const handleCopyClick = useCallback((e) => {
        e.stopPropagation();
        copyPromptToClipboard(image.imageUrl, image.id);
    }, [copyPromptToClipboard, image.imageUrl, image.id]);

    const handleCopyClickInSettings = useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();
        copyPromptToClipboard(image.imageUrl, image.id);
    }, [copyPromptToClipboard, image.imageUrl, image.id]);

    const handleCopyMarkdownLinkClick = useCallback((e) => {
        e.stopPropagation();
        handleCopyMarkdownLink(image.imageUrl, image.id, image.title);
    }, [handleCopyMarkdownLink, image.imageUrl, image.id, image.title]);

    const handleDownloadClick = useCallback((e) => {
        e.stopPropagation();
        handleDownload(image.imageUrl, image.id);
    }, [handleDownload, image.imageUrl, image.id]);

    const handleDeleteClick = useCallback((e) => {
        e.stopPropagation();
        handleDelete(image, image.id, index);
    }, [handleDelete, image, index]);

    const handleShare = useCallback((e) => {
        e.stopPropagation();
        handleShareClick(image.id);
    }, [handleShareClick, image.id]);

    const handleImageLoad = useCallback(() => {
        setImageLoaded(true);
        if (imageRef.current) {
            setIsLandscape(imageRef.current.width > imageRef.current.height);
        }
    }, []);

    const handleReportClick = useCallback((e) => {
        e.stopPropagation();
        handleReport(image.id);
    }, [handleReport, image.id]);

    const handleViewLikesClick = useCallback(async (e) => {
        e.stopPropagation();
        setLoadingLikes(true);
        const response = await handleGetLikedByUsers(image.id);
        if (response.success) {
            setLikedUsers(response.users);
            setShowLikedUsers(true);
        }
        setLoadingLikes(false);
    }, [handleGetLikedByUsers, image.id]);

    const handleProfileImageLoad = useCallback(() => {
        setProfileImageLoaded(true);
    }, []);

    const handleToggleHide = useCallback((e) => {
        e.stopPropagation();
        setIsHidden(!isHidden);
    }, [isHidden]);

    const handleToggleBlur = useCallback((e) => {
        e.stopPropagation();
        setIsBlurred(!isBlurred);
    }, [isBlurred]);

    const handleSetAsProfileClick = useCallback((e) => {
        e.stopPropagation();
        handleSetAsProfile(image.imageUrl, image.id)
    }, [image.imageUrl, image.id, handleSetAsProfile])


    return (
        <>
            <LazyLoad height={300} offset={100} once>
                <div className={`ic-image-card ${selectedImageIndex === index ? 'ic-selected' : ''} 
                        ${isMainImage ? 'ic-main-image' : ''} ${isToolGenerated ? 'ic-tool-image' : ''}`}
                    onClick={!isToolGenerated ? handleCardClick : undefined}>
                    <div className={`ic-image-wrapper ${showMoreOptions ? 'ic-show' : ''} `}>
                        {!isHidden ? (
                            <img
                                ref={imageRef}
                                src={image.imageUrl}
                                alt={image.title || `Generated by ${image.username}`}
                                className={`ic-image ${imageLoaded ? 'ic-loaded' : ''} ${isBlurred ? 'ic-blurred' : ''} ${isMainImage ? 'ic-main-image-img' : ''}`}
                                onLoad={handleImageLoad}
                            />
                        ) : (
                            <div className="ic-hidden-image-placeholder">Image Hidden</div>
                        )}
                        {!imageLoaded && !isHidden && (
                            <div className={`ic-image-placeholder ${getPlaceholderColor(image.id || index)} ${!imageLoaded ? 'ic-not-loaded' : ''}`} />
                        )}
                        <div className={`ic-heart-animation ${showHeartAnimation ? 'active' : ''}`}>
                            <Heart size={70} fill="yellow" />
                        </div>
                        {imageLoaded && (
                            <div className={`ic-image-overlay ${selectedImageIndex === index ? 'ic-hidden' : ''}`}>
                                <div className="ic-image-details">
                                    {!hideUserInfo && !isOwnProfile && !isMobile && (
                                        <div className="ic-user-info">
                                            {image.userProfileImage && (
                                                <Link to={`/profile/${image.username}`} className="ic-user-link" onClick={(e) => e.stopPropagation()}>
                                                    <div className={`ic-user-avatar-container ${image.badges?.social_ambassador ? 'ic-social-ambassador' : ''}`}>
                                                        <img
                                                            src={image.userProfileImage}
                                                            alt={image.username}
                                                            className={`ic-user-avatar ${profileImageLoaded ? 'ic-loaded' : ''}`}
                                                            onLoad={handleProfileImageLoad}
                                                        />
                                                        {image.badges?.social_ambassador && (
                                                            <span className="ic-badge-icon" title="social ambassador badge">
                                                                <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                                                                    <path d="m15,12c0,1.302-.839,2.402-2,2.816v-3.816h-2v3.816c-1.161-.414-2-1.514-2-2.816,0-1.708,1.819-3.67,3-4.708,1.181,1.038,3,3,3,4.708Zm7.08,4.175c.122,1.592-.451,3.167-1.595,4.31-1.144,1.143-2.711,1.718-4.31,1.595-1.039,1.212-2.558,1.92-4.175,1.92s-3.136-.708-4.175-1.92c-1.595.12-3.166-.452-4.31-1.595-1.144-1.144-1.717-2.718-1.595-4.31-1.212-1.039-1.92-2.558-1.92-4.175s.708-3.136,1.92-4.175c-.122-1.592.451-3.167,1.595-4.31,1.144-1.143,2.717-1.717,4.31-1.595,1.039-1.212,2.558-1.92,4.175-1.92s3.136.708,4.175,1.92c1.595-.121,3.167.452,4.31,1.595,1.144,1.144,1.717,2.718,1.595,4.31,1.212,1.039,1.92,2.558,1.92,4.175s-.708,3.136-1.92,4.175Zm-5.08-4.175c0-3.402-3.95-6.462-4.4-6.8l-.6-.45-.6.45c-.45.337-4.4,3.398-4.4,6.8,0,2.414,1.721,4.434,4,4.899v2.101h2v-2.101c2.279-.465,4-2.484,4-4.899Z" />
                                                                </svg>
                                                            </span>
                                                        )}
                                                    </div>
                                                </Link>
                                            )}
                                        </div>
                                    )}
                                    {isBlurred && image.isAdult && (
                                        <div className="ic-adult-content-overlay">
                                            <div className="ic-image-reason">{image.reason}</div>
                                            {(isOwnProfile || isAdminView) && isLoggedIn && (
                                                <div className="ic-quick-actions">
                                                    <button
                                                        className="ic-action-button ic-blur"
                                                        onClick={handleToggleBlur}
                                                        title={isBlurred ? "Unblur Image" : "Blur Image"}
                                                    >
                                                        <Image size={20} className="ic-action-icon ic-info" />
                                                        <span>Unblur</span>
                                                    </button>
                                                    <button
                                                        onClick={isAdminView ? onDeleteImage : handleDeleteClick}
                                                        className="ic-action-button ic-delete"
                                                        title="Delete"
                                                    >
                                                        <Trash2 size={20} className="ic-action-icon ic-delete" />
                                                        <span>Delete</span>
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {!isShowingInContent && !isBlurred && !isMobile && (
                                        <div className="ic-image-title">{image.title}</div>
                                    )}

                                    {showMoreOptions && (
                                        <div
                                            className="ic-more-options-backdrop"
                                            onClick={(e) => {
                                                if (e.target.className === 'ic-more-options-backdrop') {
                                                    e.stopPropagation();
                                                    setShowMoreOptions(false);
                                                    setActiveMoreOptions(false);
                                                }
                                            }}
                                        />
                                    )}

                                    <div className={`ic-image-buttons ${showMoreOptions ? 'ic-show' : ''} ${isLandscape ? 'ic-landscape' : ''}`}>
                                        {!isToolGenerated && (
                                            <>
                                                <button
                                                    className={`ic-action-button ic-image-likes ${is_liked ? 'ic-liked' : ''} ${isLandscape ? 'ic-landscape' : ''}`}
                                                    onClick={handleLikeClick}
                                                    disabled={likeInProgress[image.id]}
                                                >
                                                    <Heart
                                                        size={20}
                                                        fill={is_liked ? 'yellow' : 'none'}
                                                        color={is_liked ? 'yellow' : '#fff'} />
                                                    {image.likeCount}
                                                </button>
                                                <button
                                                    className="ic-action-button ic-image-likes ic-comment-button"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setShowComments(true);
                                                    }}
                                                    title="Comments"
                                                >
                                                    <MessageCircle size={20} className="ic-action-icon" />
                                                    {image.commentCount || 0}
                                                </button>
                                            </>
                                        )}
                                        <button
                                            className="ic-action-button ic-more-button"
                                            onClick={handleMoreClick}
                                            title="More Options"
                                        >
                                            <MoreHorizontal size={20} className="ic-action-icon" />

                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </LazyLoad>
            {showComments && (
                <CommentBox
                    imageId={image.id}
                    onClose={() => setShowComments(false)}
                    isLoggedIn={isLoggedInForCommments}
                    currentUserId={currentUser?.id} // Add this line
                />
            )}
            <LikedUsersModal
                showLikedUsers={showLikedUsers}
                setShowLikedUsers={setShowLikedUsers}
                likedUsers={likedUsers}
            />
            <ImageSettings
                title={image.title}
                isDesign={image.isDesign}
                showSettings={showSettings}
                settingsData={settingsData}
                setShowSettings={setShowSettings}
                handleCopyClickInSettings={handleCopyClickInSettings}
                buttonStates={buttonStates}
                imageId={image.id}
            />
            <MoreOptions
                showMoreOptions={showMoreOptions}
                image={image}
                buttonStates={buttonStates}
                handleShare={handleShare}
                handleCopyClick={handleCopyClick}
                handleSettingsClick={handleSettingsClick}
                onToggleAdultStatus={onToggleAdultStatus}
                isLoggedIn={isLoggedIn}
                handleDownloadClick={handleDownloadClick}
                isHidden={isHidden}
                handleToggleHide={handleToggleHide}
                isBlurred={isBlurred}
                handleToggleBlur={handleToggleBlur}
                handleCopyMarkdownLinkClick={handleCopyMarkdownLinkClick}
                handleSetAsProfileClick={handleSetAsProfileClick}
                handleDeleteClick={handleDeleteClick}
                handleProfileImageLoad={handleProfileImageLoad}
                handleReportClick={handleReportClick}
                hideUserInfo={hideUserInfo}
                isAdmin={isAdmin}
                isLikedImage={isLikedImage}
                isOwnContentImages={isOwnContentImages}
                isOwnProfile={isOwnProfile}
                isShowingInContent={isShowingInContent}
                isToolGenerated={isToolGenerated}
                onDeleteImage={onDeleteImage}
                onRemoveReportedStatus={onRemoveReportedStatus}
                profileImageLoaded={profileImageLoaded}
                handleViewLikesClick={handleViewLikesClick}
                loadingLikes={loadingLikes}
                formatTimestamp={formatTimestamp}
                moreOptionsRef={moreOptionsRef}
                setShowMoreOptions={setShowMoreOptions}
                setActiveMoreOptions={setActiveMoreOptions}
            />
        </>
    );
});

export default ImageCard;