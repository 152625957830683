import React, { useState, useEffect } from 'react';

const ExplicitContentFilter = ({ prompt, onValidPrompt, onInvalidPrompt }) => {
    const [isValid, setIsValid] = useState(true);
    const [message, setMessage] = useState('');

    useEffect(() => {
        const checkAdultContent = (words) => {
            const explicitAdultWords = [
                'porn', 'pornography', 'xxx', 'nude', 'nudity', 'naked', 'sex', 'sexual',
                'erotic', 'erotica', 'fetish', 'kink', 'kinky', 'hardcore',
                'blowjob', 'fellatio', 'cunnilingus', 'anal', 'oral', 'masturbation',
                'orgasm', 'penetration', 'threesome', 'foursome', 'orgy', 'gangbang',
                'dildo', 'vibrator', 'bondage', 'bdsm', 'dominatrix', 'sadism', 'masochism',
                'horny', 'aroused', 'topless', 'bottomless', 'striptease', 'voyeur',
                'exhibitionist', 'genitals', 'genitalia', 'vagina', 'pussy', 'cunt', 'penis',
                'cock', 'dick', 'testicles', 'scrotum', 'clitoris', 'labia', 'vulva',
                'anus', 'rectum', 'taint', 'milf', 'dilf', 'gilf', 'jailbait', 'fuck',
                'motherfucker', 'whore', 'slut', 'prostitute', 'hooker', 'whorehouse',
                'onlyfans', 'rimming', 'fisting', 'bukakke', 'creampie', 'cuckold', 'sexy',
                'hotwife', 'rape', 'molest', 'incest', 'bestiality', 'hentai', 'smut', 'sultry'
            ];

            const adultPhrases = [
                'sex toy', 'sex shop', 'adult entertainment', 'adult industry', 'adult store',
                'escort service', 'call girl', 'cam girl', 'webcam model', 'live sex',
                'barely legal', 'sugar daddy', 'sugar momma', 'red light district',
                'massage parlor', 'porn star', 'adult film', 'foot fetish', 'golden shower',
                'wife swapping', 'group sex', 'sexual abuse', 'booty call', 'dick pic',
                'revenge porn', 'deep fake', 'hot girl', 'hot woman', 'hot young', 'hot women',
                'hot female'
            ];

            const wordsArray = words.toLowerCase().split(/\s+/);

            // Check for individual explicit adult words
            if (wordsArray.some(word => explicitAdultWords.includes(word))) {
                return true;
            }

            // Check for two-word combinations
            const wordPairs = wordsArray.slice(0, -1).map((word, i) => `${word} ${wordsArray[i + 1]}`);
            if (wordPairs.some(pair => adultPhrases.includes(pair))) {
                return true;
            }

            return false;
        };

        // Usage remains the same
        const containsAdultContent = checkAdultContent(prompt);
        setIsValid(!containsAdultContent);

        if (containsAdultContent) {
            setMessage('Your prompt may contain explicit content. Please revise it.');
            onInvalidPrompt();
        } else {
            setMessage('');
            onValidPrompt();
        }
    }, [prompt, onValidPrompt, onInvalidPrompt]);

    return (
        <div className="explicit-content-filter">
            {!isValid && (
                <div className="error-message">
                    {message}
                </div>
            )}
        </div>
    );
};

export default ExplicitContentFilter;