// cacheManager.js
const CACHE_DURATION = 10 * 60 * 1000; // 10 minutes in milliseconds

class ImageDetailRealtedImagesCache {
  constructor() {
    this.cache = new Map();
  }

  set(key, data) {
    this.cache.set(key, {
      data,
      timestamp: Date.now()
    });
  }

  get(key) {
    const cached = this.cache.get(key);
    if (!cached) return null;

    const isExpired = Date.now() - cached.timestamp > CACHE_DURATION;
    if (isExpired) {
      this.cache.delete(key);
      return null;
    }

    return cached.data;
  }

  clear() {
    this.cache.clear();
  }
}

export const relatedImageCacheManager = new ImageDetailRealtedImagesCache();