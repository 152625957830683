// Home.js
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { authenticatedApiCall } from '../utils/authenticatedApiCall';
import '../css/Home.css';
import ImageCard from './ImageCard';
import useLikeImage from '../hooks/image/useLikeImage';
import useImageHandlers from '../hooks/image/useImageHandlers';
import Masonry from 'react-masonry-css';
import { useDocumentHead } from '../hooks/useDocumentHead';

const breakpointColumnsObj = {
  default: 5,
  1200: 5,
  1024: 4,
  768: 3,
  576: 2,
  460: 2,
  330: 1,
};

const CACHE_DURATION = 5 * 60 * 1000;

const Home = () => {
  const [feedImages, setFeedImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const loader = useRef(null);
  const location = useLocation();
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const navigate = useNavigate();
  const lastFetchTime = useRef(null);

  useDocumentHead(
    'Home - Explore AI-Generated Images | AI Tools',
    'Welcome to the AI Tools home page! Discover a dynamic feed of AI-generated images. Like, download, upvote and interact with unique creations to fuel your creativity.'
  );

  const {
    images,
    setImages: setLikeImages,
    handleLike: originalHandleLike,
    likeInProgress,
    error: likeError
  } = useLikeImage(feedImages);

  const {
    buttonStates,
    error,
    handleDownload,
    handleCopyPrompt,
    handleReport,
    handleShareClick,
    setError,
    handleShowSettings,
    handleGetLikedByUsers,
  } = useImageHandlers();

  const saveToCache = useCallback((images, page, hasMore) => {
    const cacheData = {
      images,
      page,
      hasMore,
      timestamp: Date.now()
    };
    localStorage.setItem('homeImagesCache', JSON.stringify(cacheData));
  }, []);

  const updateCacheLikeStatus = useCallback((imageId, newLikeStatus, likeCountDelta) => {
    const cachedData = localStorage.getItem('homeImagesCache');
    if (cachedData) {
      const parsedCache = JSON.parse(cachedData);
      const updatedImages = parsedCache.images.map(img => 
        img.id === imageId ? {
          ...img,
          is_liked: newLikeStatus,
          likeCount: img.likeCount + likeCountDelta
        } : img
      );
      saveToCache(updatedImages, parsedCache.page, parsedCache.hasMore);
    }
  }, [saveToCache]);

  const handleLike = useCallback(async (imageId) => {
    const { success, newLikeStatus } = await originalHandleLike(imageId);
    if (success) {
      const likeCountDelta = newLikeStatus ? 1 : -1;
      updateCacheLikeStatus(imageId, newLikeStatus, likeCountDelta);
    }
  }, [originalHandleLike, updateCacheLikeStatus]);

  const loadFromCache = useCallback(() => {
    const cachedData = localStorage.getItem('homeImagesCache');
    if (cachedData) {
      const { images, page, hasMore, timestamp } = JSON.parse(cachedData);
      if (Date.now() - timestamp < CACHE_DURATION) {
        setFeedImages(images);
        setPage(page);
        setHasMore(hasMore);
        lastFetchTime.current = timestamp;
        return true;
      } else {
        localStorage.removeItem('homeImagesCache');
      }
    }
    return false;
  }, []);

  const fetchImages = useCallback(async () => {
    if (loading || !hasMore) return;
    setLoading(true);
    try {
      const response = await authenticatedApiCall(`/get-random-images?page=${page}&per_page=20`, 'GET');
      if (response.length > 0) {
        setFeedImages(prevImages => {
          const newImages = response.filter(newImg => !prevImages.some(existingImg => existingImg.id === newImg.id));
          const updatedImages = [...prevImages, ...newImages];
          saveToCache(updatedImages, page + 1, true);
          return updatedImages;
        });
        setPage(prevPage => prevPage + 1);
        lastFetchTime.current = Date.now();
      } else {
        setHasMore(false);
        saveToCache(feedImages, page, false);
      }
    } catch (err) {
      setError('Error fetching images: ' + err.message);
    } finally {
      setLoading(false);
    }
  }, [loading, hasMore, page, setError, feedImages, saveToCache]);

  const resetImageState = useCallback(() => {
    setFeedImages([]);
    setPage(1);
    setHasMore(true);
    setLoading(false);
    localStorage.removeItem('homeImagesCache');
    lastFetchTime.current = null;
  }, []);

  const handleRefresh = useCallback(() => {
    window.scrollTo({ top: 0 });
    resetImageState();
  }, [resetImageState]);

  useEffect(() => {
    if (feedImages.length === 0 && !loading) {
      if (!loadFromCache()) {
        fetchImages();
        fetchImages();
        fetchImages();
      }
    }
  }, [feedImages.length, fetchImages, loading, loadFromCache]);

  const handleImageClick = useCallback((index) => {
    if (window.innerWidth <= 768) {
      setSelectedImageIndex(prevIndex => prevIndex === index ? null : index);
    }
  }, []);

  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting && !loading && hasMore) {
      fetchImages();
    }
  }, [loading, hasMore, fetchImages]);

  useEffect(() => {
    setLikeImages(feedImages);
  }, [feedImages, setLikeImages]);

  useEffect(() => {
    if (likeError) {
      setError(likeError);
    }
  }, [likeError, setError]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest('.home-image-card')) {
        setSelectedImageIndex(null);
      }
    };

    document.addEventListener('click', handleOutsideClick);
    return () => document.removeEventListener('click', handleOutsideClick);
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [location.pathname]);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "600px",
      threshold: 0.1
    };
    const observer = new IntersectionObserver(handleObserver, option);
    const currentLoader = loader.current; 
  
    if (currentLoader) observer.observe(currentLoader);
  
    return () => {
      if (currentLoader) observer.unobserve(currentLoader); 
    };
  }, [handleObserver]);

  const renderItems = useCallback(() => {
    return images.map((image, index) => (
      <ImageCard
        key={image.id}
        image={{
          ...image,
          is_liked: image.is_liked
        }}
        index={index}
        selectedImageIndex={selectedImageIndex}
        handleImageClick={handleImageClick}
        handleLike={handleLike}
        copyPromptToClipboard={handleCopyPrompt}
        handleDownload={(url, id) => handleDownload(url, id, image.title)}
        handleGetLikedByUsers={handleGetLikedByUsers}
        handleReport={handleReport}
        handleShareClick={handleShareClick}
        buttonStates={buttonStates}
        likeInProgress={likeInProgress}
        handleShowSettings={handleShowSettings}
      />
    ));
  }, [images, selectedImageIndex, handleImageClick, handleLike, handleCopyPrompt, 
    handleDownload, buttonStates, likeInProgress, handleReport, handleShareClick, 
    handleShowSettings, handleGetLikedByUsers]);

  const SkeletonCard = () => (
    <div className="home-image-card home-skeleton">
      <div className="home-skeleton-image"></div>
      <div className="home-skeleton-text"></div>
      <div className="home-skeleton-text home-skeleton-text-short"></div>
    </div>
  );

  const renderEndContent = () => {
    if (!loading && !hasMore) {
      return (
        <div className="home-end-content">
          <p className="home-no-more-images">You've reached the end of your feed</p>
          <div className="home-end-buttons">
            <button
              onClick={handleRefresh}
              className="home-end-button refresh"
            >
              Refresh
            </button>
            <button
              className="home-end-button create"
              onClick={() => navigate('/create')}
            >
              Create New
            </button>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="home-home-container margin margin-home">
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {loading && images.length === 0
          ? Array(20).fill().map((_, index) => <SkeletonCard key={index} />)
          : renderItems()}
        {loading && images.length > 0 && Array(10).fill().map((_, index) => <SkeletonCard key={`loading-${index}`} />)}
      </Masonry>

      {renderEndContent()}
      {error && <p className="home-error-text">{error}</p>}
      {hasMore && <div ref={loader} />}
    </div>
  );
};

export default Home;