import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { authenticatedApiCall } from '../utils/authenticatedApiCall';
import ImageCard from './ImageCard';
import Masonry from 'react-masonry-css';
import { ArrowLeft, Loader } from 'lucide-react';
import '../css/ImageDetailView.css';
import useImageHandlers from '../hooks/image/useImageHandlers';
import useLikeImage from '../hooks/image/useLikeImage';
import { isLoggedIn } from '../utils/cookies';
import { relatedImageCacheManager } from './ImageDetailRealtedImagesCache';
import { useDocumentHead } from '../hooks/useDocumentHead'


const breakpointColumnsObj = {
  default: 4,
  1200: 4,
  1024: 3,
  768: 2,
  400: 2,
  350: 1
};

const ImageDetailView = () => {
  const { imageId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [mainImageLoading, setMainImageLoading] = useState(!location.state?.image);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  useDocumentHead(
    'Image View - Explore and Discover Related Images | AI Tools',
    'View detailed images and explore related visuals on the AI Tools image page. Discover unique creations, engage with stunning artwork, and find inspiration for your projects.'
  );

  // Get the main image data from the location state
  const initialMainImage = location.state?.image;

  const {
    buttonStates,
    error,
    handleDownload,
    handleCopyPrompt,
    handleReport,
    handleShareClick,
    setError,
    handleShowSettings,
    handleGetLikedByUsers,
  } = useImageHandlers();

  const {
    images,
    setImages,
    handleLike,
    likeInProgress,
    error: likeError
  } = useLikeImage(initialMainImage ? [initialMainImage] : []);

  // Rest of your existing code remains the same
  const fetchMainImage = useCallback(async () => {
    if (!initialMainImage && imageId) {
      setMainImageLoading(true);
      try {
        const mainImageData = await authenticatedApiCall(`/get-image/${imageId}`, 'GET');
        setImages(prevImages => {
          const newImages = [...prevImages];
          if (!newImages.some(img => img.id === mainImageData.id)) {
            newImages.unshift(mainImageData);
          }
          return newImages;
        });
      } catch (err) {
        console.error('Error fetching main image:', err);
        setError('Error fetching main image: ' + err.message);
        isLoggedIn() ? navigate('/create') : navigate('/login');
      } finally {
        setMainImageLoading(false);
      }
    }
  }, [imageId, initialMainImage, setImages, setError, navigate]);

  const { mainImage, relatedImages } = useMemo(() => {
    if (images.length === 0) {
      return { mainImage: null, relatedImages: [] };
    }

    const main = initialMainImage
      ? images.find(img => img.id === initialMainImage.id) || initialMainImage
      : images[0];
    const related = images.filter(img => img.id !== main.id);
    return { mainImage: main, relatedImages: related };
  }, [images, initialMainImage]);

  const fetchRelatedImages = useCallback(async () => {
    setLoading(true);
    try {
      const cachedImages = relatedImageCacheManager.get(imageId);
      if (cachedImages) {
        setImages(prevImages => {
          const newImages = [...prevImages];
          cachedImages.forEach(newImg => {
            if (!newImages.some(img => img.id === newImg.id)) {
              newImages.push(newImg);
            }
          });
          return newImages;
        });
        setLoading(false);
        return;
      }

      const relatedImagesResponse = await authenticatedApiCall(`/get-related-images/${imageId}`, 'GET');
      relatedImageCacheManager.set(imageId, relatedImagesResponse);

      setImages(prevImages => {
        const newImages = [...prevImages];
        relatedImagesResponse.forEach(newImg => {
          if (!newImages.some(img => img.id === newImg.id)) {
            newImages.push(newImg);
          }
        });
        return newImages;
      });
    } catch (err) {
      console.error('Error fetching related images:', err);
      setError('Error fetching related images: ' + err.message);
    } finally {
      setLoading(false);
    }
  }, [imageId, setError, setImages]);

  useEffect(() => {
    fetchMainImage();
  }, [fetchMainImage, navigate]);

  useEffect(() => {
    if (mainImage) {
      fetchRelatedImages();
    }
  }, [fetchRelatedImages, mainImage]);

  useEffect(() => {
    if (likeError) {
      setError(likeError);
    }
  }, [likeError, setError]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleImageClick = useCallback((index) => {
    if (window.innerWidth <= 768) {
      setSelectedImageIndex(prevIndex => prevIndex === index ? null : index);
    }
  }, []);

  const handleUsernameClick = (username) => {
    navigate(`/profile/${username}`);
  };

  const renderSkeletonLoading = () => {
    return Array.from({ length: 8 }).map((_, index) => (
      <div key={`skeleton-${index}`} className="idv-skeleton-item">
        <div className="idv-skeleton-image"></div>
        <div className="idv-skeleton-text"></div>
      </div>
    ));
  };

  if (error) return <div className="idv-error">{error}</div>;
  if (mainImageLoading) return <div className="idv-loading margin"><Loader size={22} className="animate-spin" /></div>;
  if (!mainImage) return null;

  return (
    <div className="idv-container margin">
      <button className="idv-back-button" onClick={handleBack}>
        <ArrowLeft size={24} />
        Back
      </button>
      <div className="idv-main-image-container">
        <ImageCard
          image={mainImage}
          index={0}
          selectedImageIndex={selectedImageIndex}
          handleImageClick={handleImageClick}
          handleLike={handleLike}
          copyPromptToClipboard={handleCopyPrompt}
          handleDownload={(url, id) => handleDownload(url, id, mainImage.title)}
          handleReport={handleReport}
          handleShareClick={handleShareClick}
          buttonStates={buttonStates}
          likeInProgress={likeInProgress}
          handleUsernameClick={handleUsernameClick}
          isMainImage={true}
          handleShowSettings={handleShowSettings}
          handleGetLikedByUsers={handleGetLikedByUsers}
        />
      </div>
      <h3 className="idv-related-images-title">More Like This</h3>
      {loading ? (
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {renderSkeletonLoading()}
        </Masonry>
      ) : relatedImages.length > 0 ? (
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {relatedImages.map((image, index) => (
            <ImageCard
              key={`${image.id}-${index}`}
              image={image}
              index={index + 1}
              selectedImageIndex={selectedImageIndex}
              handleImageClick={handleImageClick}
              handleLike={handleLike}
              copyPromptToClipboard={handleCopyPrompt}
              handleDownload={(url, id) => handleDownload(url, id, image.title)}
              handleReport={handleReport}
              handleShareClick={handleShareClick}
              buttonStates={buttonStates}
              likeInProgress={likeInProgress}
              handleUsernameClick={handleUsernameClick}
              handleShowSettings={handleShowSettings}
              handleGetLikedByUsers={handleGetLikedByUsers}
              isLoggedIn={isLoggedIn()}
              isRealtedImage={true}
            />
          ))}
        </Masonry>
      ) : (
        <p className="idv-no-related-images">No related images found</p>
      )}
    </div>
  );
};

export default ImageDetailView;