import React, { useState, useRef, useEffect } from 'react';
import { Send, Edit2, Trash2, Heart, Reply, X } from 'lucide-react';
import '../../css/CommentBox.css';
import useCommentHandlers from '../../hooks/image/useCommentHandlers';
import { formatTimestamp } from '../../utils/dateFormatter';

const CommentBox = ({ imageId, onClose, isLoggedIn, currentUserId }) => {
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [replyTo, setReplyTo] = useState(null);
    const [editingComment, setEditingComment] = useState(null);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [loadingReplies, setLoadingReplies] = useState({});
    const [isLoadingComments, setIsLoadingComments] = useState(false);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [pendingLikes, setPendingLikes] = useState(new Set());
    const commentBoxRef = useRef(null);

    const {
        handleCreateComment,
        handleDeleteComment,
        handleGetComments,
        handleUpdateComment,
        handleToggleLike,
        handleGetReplies
    } = useCommentHandlers();

    const loadComments = async () => {
        setIsLoadingComments(true);
        const response = await handleGetComments(imageId, 1);
        if (response.success) {
            setComments(response.comments);
            setHasMore(response.has_more);
        }
        setIsLoadingComments(false);
    };

    const loadMoreComments = async () => {
        setIsLoadingMore(true);
        const response = await handleGetComments(imageId, page);
        if (response.success) {
            setComments(prev => [...prev, ...response.comments]);
            setHasMore(response.has_more);
        }
        setIsLoadingMore(false);
    };

    const loadReplies = async (commentId) => {
        setLoadingReplies(prev => ({ ...prev, [commentId]: true }));
        const response = await handleGetReplies(commentId);
        if (response.success) {
            setComments(prev => {
                const commentIndex = prev.findIndex(c => c.id === commentId);
                if (commentIndex !== -1) {
                    const updatedComments = [...prev];
                    updatedComments[commentIndex] = {
                        ...updatedComments[commentIndex],
                        replies: response.replies,
                        showReplies: true
                    };
                    return updatedComments;
                }
                return prev;
            });
        }
        setLoadingReplies(prev => ({ ...prev, [commentId]: false }));
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (commentBoxRef.current && !commentBoxRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [onClose]);

    useEffect(() => {
        if (imageId) {
            if (page === 1) {
                loadComments();
            } else {
                loadMoreComments();
            }
        }
        // eslint-disable-next-line
    }, [imageId, page]);

    const updateCommentOptimistically = (commentId, updates) => {
        setComments(prevComments => {
            return prevComments.map(comment => {
                if (comment.id === commentId) {
                    return { ...comment, ...updates };
                }
                if (comment.replies) {
                    return {
                        ...comment,
                        replies: comment.replies.map(reply =>
                            reply.id === commentId ? { ...reply, ...updates } : reply
                        )
                    };
                }
                return comment;
            });
        });
    };

    const handleLike = async (commentId) => {
        if (!isLoggedIn || pendingLikes.has(commentId)) return;

        setPendingLikes(prev => new Set(prev).add(commentId));

        // Find the comment to determine current like status
        const comment = comments.find(c => c.id === commentId) ||
            comments.flatMap(c => c.replies || []).find(r => r.id === commentId);

        const isCurrentlyLiked = comment?.likes.includes(currentUserId);

        // Optimistically update the UI
        updateCommentOptimistically(commentId, {
            likes: isCurrentlyLiked
                ? comment.likes.filter(id => id !== currentUserId)
                : [...comment.likes, currentUserId],
            like_count: isCurrentlyLiked
                ? comment.like_count - 1
                : comment.like_count + 1
        });

        try {
            const response = await handleToggleLike(commentId);
            if (!response.success) {
                // Revert the optimistic update if the request failed
                updateCommentOptimistically(commentId, {
                    likes: comment.likes,
                    like_count: comment.like_count
                });
            }
        } catch (error) {
            // Revert the optimistic update on error
            updateCommentOptimistically(commentId, {
                likes: comment.likes,
                like_count: comment.like_count
            });
        } finally {
            setPendingLikes(prev => {
                const next = new Set(prev);
                next.delete(commentId);
                return next;
            });
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!newComment.trim() || !isLoggedIn) return;

        const response = await handleCreateComment(
            newComment,
            imageId,
            replyTo?.id
        );

        if (response.success) {
            setNewComment('');
            setReplyTo(null);
            loadComments(); // Reset to first page when new comment is added
            setPage(1);
        }
    };

    const handleEdit = async (commentId, content) => {
        const response = await handleUpdateComment(commentId, content);
        if (response.success) {
            setEditingComment(null);
            loadComments();
        }
    };

    const handleDelete = async (commentId) => {
        const response = await handleDeleteComment(commentId);
        if (response.success) {
            loadComments();
        }
    };


    const handleLoadMore = () => {
        if (hasMore && !isLoadingMore) {
            setPage(prev => prev + 1);
        }
    };

    const renderComment = (comment, isReply = false) => (
        <div key={comment.id} className={`cbx-comment ${isReply ? 'cbx-reply' : ''}`}>
            <div className="cbx-comment-header">
                <img src={comment.user_avatar} alt={comment.user_name} className="cbx-avatar" />
                <span className="cbx-username">{comment.user_name}</span>
                <span className="cbx-timestamp">
                    {formatTimestamp(comment.created_at)}
                </span>
            </div>

            {editingComment?.id === comment.id ? (
                <form onSubmit={(e) => {
                    e.preventDefault();
                    handleEdit(comment.id, editingComment.content);
                }}>
                    <textarea
                        value={editingComment.content}
                        onChange={(e) => setEditingComment({
                            ...editingComment,
                            content: e.target.value
                        })}
                        className="cbx-edit-textarea"
                    />
                    <div className="cbx-edit-actions">
                        <button type="submit" className="cbx-button">Save</button>
                        <button
                            type="button"
                            onClick={() => setEditingComment(null)}
                            className="cbx-button cbx-cancel"
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            ) : (
                <p className="cbx-content">{comment.content}</p>
            )}

            <div className="cbx-actions">
                <button
                    className={`cbx-action-button ${comment.likes.includes(currentUserId) ? 'cbx-liked' : ''
                        } ${pendingLikes.has(comment.id) ? 'cbx-pending' : ''}`}
                    onClick={() => handleLike(comment.id)}
                    disabled={pendingLikes.has(comment.id)}
                >
                    <Heart size={16} /> {comment.like_count}
                </button>
                {!isReply && (
                    <button
                        className="cbx-action-button"
                        onClick={() => setReplyTo(comment)}
                    >
                        <Reply size={16} /> Reply
                    </button>
                )}
                {comment.user_id === currentUserId && (
                    <>
                        <button
                            className="cbx-action-button"
                            onClick={() => setEditingComment(comment)}
                        >
                            <Edit2 size={16} /> Edit
                        </button>
                        <button
                            className="cbx-action-button"
                            onClick={() => handleDelete(comment.id)}
                        >
                            <Trash2 size={16} /> Delete
                        </button>
                    </>
                )}
            </div>

            {comment.replies_count > 0 && !isReply && !comment.showReplies && (
                <button
                    className="cbx-load-replies"
                    onClick={() => loadReplies(comment.id)}
                    disabled={loadingReplies[comment.id]}
                >
                    {loadingReplies[comment.id] ? 'Loading...' : `View ${comment.replies_count} replies`}
                </button>
            )}

            {comment.showReplies && comment.replies && (
                <div className="cbx-replies">
                    {comment.replies.map(reply => renderComment(reply, true))}
                </div>
            )}
        </div>
    );

    return (
        <div className="cbx-overlay">
            <div ref={commentBoxRef} className="cbx-container">
                <div className="cbx-header">
                    <h3>Comments</h3>
                    <button className="cbx-close" onClick={onClose}>
                        <X size={20} />
                    </button>
                </div>

                <div className="cbx-comments-list">
                    {isLoadingComments && comments.length === 0 ? (
                        <div className="cbx-loading">Loading comments...</div>
                    ) : (
                        <>
                            {comments.map(comment => renderComment(comment))}
                            {hasMore && !isLoadingComments && (
                                <button
                                    className="cbx-load-more"
                                    onClick={handleLoadMore}
                                    disabled={isLoadingMore}
                                >
                                    {isLoadingMore ? 'Loading more comments...' : 'Load more comments'}
                                </button>
                            )}
                        </>
                    )}
                </div>

                {replyTo && (
                    <div className="cbx-replying-to">
                        Replying to {replyTo.user_name}
                        <button onClick={() => setReplyTo(null)}>
                            <X size={16} />
                        </button>
                    </div>
                )}

                {isLoggedIn ? (
                    <form className="cbx-comment-form" onSubmit={handleSubmit}>
                        <textarea
                            value={newComment}
                            onChange={(e) => setNewComment(e.target.value)}
                            placeholder={replyTo ? "Write a reply..." : "Write a comment..."}
                            className="cbx-input"
                        />
                        <button type="submit" className="cbx-submit" disabled={!newComment.trim()}>
                            <Send size={20} />
                        </button>
                    </form>
                ) : (
                    <div className="cbx-login-prompt">
                        Please log in to comment
                    </div>
                )}
            </div>
        </div>
    );
};

export default CommentBox;