import React, { useState, useRef } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { Share2, Download, Loader2 } from 'lucide-react';
import '../../css/ProfileCardShare.css'

const ProfileCardShare = ({ username }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [isDownloading, setIsDownloading] = useState(false);
  const [error, setError] = useState('');
  const cardRef = useRef(null);

  const profileUrl = `https://ai.devsaura.com/profile/${username}`;

  const handleDownload = async () => {
    if (!cardRef.current) return;
    
    setIsDownloading(true);
    setError('');

    try {
      // Dynamically import html2canvas only when needed
      const html2canvas = (await import('html2canvas')).default;
      
      // Wait for the QR code to fully render
      await new Promise(resolve => setTimeout(resolve, 100));
      
      const canvas = await html2canvas(cardRef.current, {
        useCORS: true,
        scale: 2, // Higher quality output
        backgroundColor: null, // Let the card's background show through
        logging: false,
        onclone: (documentClone) => {
          // Ensure styles are properly applied in the cloned document
          const clonedCard = documentClone.querySelector('.pcs-card');
          if (clonedCard) {
            const originalCard = cardRef.current;
            const computedStyle = window.getComputedStyle(originalCard);
            clonedCard.style.backgroundColor = computedStyle.backgroundColor;
            clonedCard.style.width = `${originalCard.offsetWidth}px`;
            clonedCard.style.height = `${originalCard.offsetHeight}px`;
          }
        }
      });

      // Use PNG format with maximum quality
      const image = canvas.toDataURL('image/png', 1.0);
      const link = document.createElement('a');
      link.href = image;
      link.download = `${username}-profile-card.png`;
      link.click();
    } catch (err) {
      console.error('Error generating image:', err);
      setError('Failed to generate image. Please try again.');
    } finally {
      setIsDownloading(false);
    }
  };

  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="settings-button"
      >
        <Share2 size={20} />
        <span>Share Profile Card</span>
      </button>

      {isOpen && (
        <div 
          className="pcs-overlay"
          onClick={() => setIsOpen(false)}
        >
          <div
            className="pcs-modal"
            onClick={handleModalClick}
          >
            <div className="pcs-space-y-4">
              <h3 className="pcs-modal-title">Share Your Profile</h3>

              {error && (
                <div className="pcs-error-message">
                  {error}
                </div>
              )}

              {/* Card Preview */}
              <div
                ref={cardRef}
                className="pcs-card"
              >
                <div className="pcs-flex-center">
                  <QRCodeSVG
                    value={profileUrl}
                    size={200}
                    level="H"
                    includeMargin={true}
                  />
                </div>

                <div className="pcs-card-text">
                  <p className="pcs-url-text">
                    {profileUrl}
                  </p>
                  {message && (
                    <p className="pcs-message-text">
                      {message}
                    </p>
                  )}
                </div>
              </div>

              {/* Message Input */}
              <div>
                <label className="pcs-input-label">
                  Add a message (optional)
                </label>
                <input
                  type="text"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  maxLength={100}
                  placeholder="Write a short message..."
                  className="pcs-input"
                  disabled={isDownloading}
                />
              </div>

              {/* Actions */}
              <div className="pcs-action-buttons">
                <button
                  onClick={() => setIsOpen(false)}
                  className="pcs-cancel-button"
                  disabled={isDownloading}
                >
                  Cancel
                </button>
                <button
                  onClick={handleDownload}
                  className="pcs-download-button"
                  disabled={isDownloading}
                >
                  {isDownloading ? (
                    <>
                      <Loader2 size={16} className="animate-spin" />
                      <span>Generating...</span>
                    </>
                  ) : (
                    <>
                      <Download size={16} />
                      <span>Download Card</span>
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProfileCardShare;