export const formatTimestamp = (timestamp, isCommentDate = false) => {
    if (!timestamp) return '';

    // Convert timestamp to Date object
    let date;
    if (timestamp.$date) {
        date = new Date(timestamp.$date);
    } else if (typeof timestamp === 'string') {
        date = new Date(timestamp);
    } else {
        return '';
    }

    // Adjust for comments if they are from MongoDB (e.g., UTC)
    if (isCommentDate) {
        const offset = new Date().getTimezoneOffset();
        date.setMinutes(date.getMinutes() - offset); // adjust to local time zone
    }

    const now = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);

    // Return relative time format
    if (diffInSeconds < 60) {
        return 'Just now';
    } else if (diffInSeconds < 3600) {
        const minutes = Math.floor(diffInSeconds / 60);
        return `${minutes}m ago`;
    } else if (diffInSeconds < 86400) {
        const hours = Math.floor(diffInSeconds / 3600);
        return `${hours}h ago`;
    } else if (diffInSeconds < 604800) {
        const days = Math.floor(diffInSeconds / 86400);
        return `${days}d ago`;
    } else {
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });
    }
};