import React from 'react';
import ToolContent from '../ToolContent';

const ContentGenerator = () => {
  const formFields = [
    {
      name: 'title',
      type: 'text',
      label: 'Title',
      placeholder: 'Enter your content title',
      required: true,
    },
    {
      name: 'contentType',
      type: 'select',
      label: 'Content Type',
      placeholder: 'Choose content type',
      options: [
        { value: 'article', label: 'Article' },
        { value: 'story', label: 'Story' },
        { value: 'blog', label: 'Blog Post' },
        { value: 'script', label: 'Script' },
        { value: 'poem', label: 'Poem' },
        { value: 'product', label: 'Product Description' },
        { value: 'social', label: 'Social Media Post' },
      ],
    },
    { 
      name: 'prompt', 
      type: 'textarea', 
      label: 'Content Description',
      placeholder: 'Describe your content requirements...',
      required: true,
      rows: 3
    },
    {
      name: 'visualStyle',
      type: 'select',
      label: 'Visual Style',
      placeholder: 'Select image style',
      options: [
        { value: 'Realistic', label: 'Realistic' },
        { value: 'Cartoonish', label: 'Cartoonish' },
        { value: 'Watercolor', label: 'Watercolor' },
        { value: 'Digital Art', label: 'Digital Art' },
        { value: 'Minimalist', label: 'Minimalist' },
        { value: 'Abstract', label: 'Abstract' },
        { value: 'Pop Art', label: 'Pop Art' },
      ],
    },
    {
      name: 'imageDimension',
      type: 'select',
      label: 'Image Size',
      placeholder: 'Choose image dimensions',
      options: [
        { value: 'portrait', label: 'Portrait (1080x1400)' },
        { value: 'landscape', label: 'Landscape (1400x1080)' },
        { value: 'square', label: 'Square (1080x1080)' },
        { value: 'wide', label: 'Wide (1920x1080)' },
        { value: 'banner', label: 'Banner (1500x500)' },
        { value: 'story', label: 'Story/Mobile (1080x1920)' },
      ],
    },
  ];

  return (
    <ToolContent
      formFields={formFields}
      apiEndpoint={process.env.REACT_APP_CONTENT_GENERATOR}
      toolTitle="Content Generator"
      imageName="content_images"
    />
  );
};

export default ContentGenerator;