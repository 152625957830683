import React, { useState, useEffect, useCallback, useRef } from 'react';
import { authenticatedApiCall } from '../../../utils/authenticatedApiCall';
import useMessage from '../../../hooks/useNotify';
import DesignViewer from './DesignViewer';
import './DesignGallery.css';
import { Link } from 'react-router-dom';
import { RefreshCcw } from 'lucide-react';

const DesignGallery = ({
  type,
  title,
  apiEndpoint,
  cardHeight = '200px',
  maxWidth = '1200px',
  itemsPerRow = { default: '250px', mobile: '200px' },
  designViewerProps = {},
}) => {
  const [designs, setDesigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedDesign, setSelectedDesign] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showDesignViewer, setShowDesignViewer] = useState(false);
  const { MessageDisplay, setMessage } = useMessage();
  const lastFetchTime = useRef(null);
  const initialLoadComplete = useRef(false);

  // Cache key specific to design type
  const cacheKey = `designGalleryCache_${type}`;
  const CACHE_DURATION = 60 * 60 * 1000; // 60 minutes

  const saveToCache = useCallback((designs) => {
    const cacheData = {
      designs,
      timestamp: Date.now()
    };
    localStorage.setItem(cacheKey, JSON.stringify(cacheData));
  }, [cacheKey]);

  const fetchDesigns = useCallback(async () => {
    try {
      setLoading(true);
      const response = await authenticatedApiCall(apiEndpoint);
      console.log("res=>", response);

      // Handle different response structures
      let designsData = [];
      if (type === 'logo' && response.logo_designs) {
        designsData = response.logo_designs;
      } else if (type === 'thumbnail' && response.thumbnail_designs) {
        designsData = response.thumbnail_designs;
      } else if (response.designs) {
        designsData = response.designs;
      }

      setDesigns(designsData);
      saveToCache(designsData);
    } catch (error) {
      setMessage(error.message || `Failed to fetch ${type} designs`, 'error');
    } finally {
      setLoading(false);
    }
  }, [apiEndpoint, type, setMessage, saveToCache]);


  const loadFromCache = useCallback(() => {
    const cachedData = localStorage.getItem(cacheKey);
    if (cachedData) {
      const { designs, timestamp } = JSON.parse(cachedData);
      if (Date.now() - timestamp < CACHE_DURATION) {
        setDesigns(designs);
        lastFetchTime.current = timestamp;
        setLoading(false);
        return true;
      }
      localStorage.removeItem(cacheKey);
    }
    return false;
  }, [cacheKey, CACHE_DURATION]);

  const updateCacheStatus = useCallback((designId, newStatus) => {
    const cachedData = localStorage.getItem(cacheKey);
    if (cachedData) {
      const parsedCache = JSON.parse(cachedData);
      const updatedDesigns = parsedCache.designs.map(design =>
        design.id === designId ? { ...design, is_approved: newStatus } : design
      );
      saveToCache(updatedDesigns);
    }
  }, [cacheKey, saveToCache]);

  const handleApprove = useCallback(async (designId) => {
    try {
      const response = await authenticatedApiCall(`${apiEndpoint}/${designId}/approve`, 'PUT');
      if (response.success) {
        setMessage(`${type} design approved successfully`, 'success');
        fetchDesigns();
        setSelectedDesign(null);
        updateCacheStatus(designId, true);
      }
    } catch (error) {
      setMessage(error.message || `Failed to approve ${type} design`, 'error');
    }
  }, [apiEndpoint, type, fetchDesigns, setMessage, updateCacheStatus]);

  const handleDelete = useCallback(async (designId) => {
    if (window.confirm(`Are you sure you want to delete this ${type} design?`)) {
      try {
        const response = await authenticatedApiCall(`${apiEndpoint}/${designId}`, 'DELETE');
        if (response.success) {
          setMessage(`${type} design deleted successfully`, 'success');
          fetchDesigns();
          setSelectedDesign(null);
          updateCacheStatus(designId, false);
        }
      } catch (error) {
        setMessage(error.message || `Failed to delete ${type} design`, 'error');
      }
    }
  }, [apiEndpoint, type, fetchDesigns, setMessage, updateCacheStatus]);

  const checkAdminStatus = useCallback(() => {
    try {
      const userData = JSON.parse(localStorage.getItem('user') || '{}');
      setIsAdmin(userData?.is_admin || false);
    } catch (error) {
      console.error('Failed to check admin status:', error);
      setIsAdmin(false);
    }
  }, []);

  // Initial load effect
  useEffect(() => {
    if (!initialLoadComplete.current) {
      if (!loadFromCache()) {
        fetchDesigns();
      }
      initialLoadComplete.current = true;
    }
  }, [loadFromCache, fetchDesigns]);

  // Check admin status on mount
  useEffect(() => {
    checkAdminStatus();
  }, [checkAdminStatus]);

  const handleDesignClick = useCallback((design) => {
    setSelectedDesign(prev => prev?.id === design.id ? null : design);
  }, []);

  const handleUseDesign = useCallback((design, e) => {
    e.stopPropagation();
    setShowDesignViewer(true);
    setSelectedDesign(design);
  }, []);

  const handleRefresh = useCallback(() => {
    localStorage.removeItem(cacheKey);
    fetchDesigns();
  }, [cacheKey, fetchDesigns]);

  if (loading) {
    return (
      <div className="dg-container" style={{ maxWidth }}>
        <div className="dg-loading">Loading {type} designs...</div>
      </div>
    );
  }

  return (
    <div className="dg-container" style={{ maxWidth }}>
      <MessageDisplay />
      <h1 className="dg-title">{title}</h1>
      <button className="dg-refresh-button" onClick={handleRefresh}>
        <RefreshCcw size={18} />
      </button>

      <div className="dg-grid" style={{
        gridTemplateColumns: `repeat(auto-fill, minmax(${itemsPerRow.default}, 1fr))`
      }}>
        {designs.map((design) => (
          <div
            key={design.id}
            className={`dg-design-card ${selectedDesign?.id === design.id ? 'dg-selected' : ''}`}
            onClick={() => handleDesignClick(design)}
          >
            {isAdmin && (
              <div className="dg-status-badge" data-status={design.is_approved ? 'approved' : 'pending'}>
                {design.is_approved ? 'Approved' : 'Pending'}
              </div>
            )}
            <img
              src={design.image_url}
              alt={`${type} design by ${design.submitted_by}`}
              className="dg-design-image"
              style={{
                height: cardHeight,
                width: '100%',
                objectFit: 'cover'
              }}
            />
            <div className="dg-design-info">
              <p className="dg-designer">
                By: <Link to={`/profile/${design.submitted_by}`}>
                  {design.submitted_by}
                </Link>
              </p>
              {selectedDesign?.id === design.id && (
                <div className="dg-admin-controls">
                  <button
                    className="dg-button dg-button-use"
                    onClick={(e) => handleUseDesign(design, e)}
                  >
                    Use Design
                  </button>
                  {isAdmin && (
                    <>
                      {!design.is_approved && (
                        <button
                          className="dg-button dg-button-approve"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleApprove(design.id);
                          }}
                        >
                          Approve Design
                        </button>
                      )}
                      <button
                        className="dg-button dg-button-delete"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDelete(design.id);
                        }}
                      >
                        Delete Design
                      </button>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      {showDesignViewer && selectedDesign && (
        <DesignViewer
          design={selectedDesign}
          onClose={() => setShowDesignViewer(false)}
          type={type}
          {...designViewerProps}
        />
      )}
    </div>
  );
};

export default DesignGallery;