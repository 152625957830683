import React, { useState } from 'react';
import { Bookmark, Flag, ArrowUpCircle } from 'lucide-react';
import '../css/ContentCardButtons.css';

const ContentCardButtons = ({
    contentId,
    contentItem,
    voteCount = 0,
    votedBy = [],
    savedBy = [],
    reportedBy = [],
    isLoggedIn,
    currentUsername,
    currentId,
    onVote,
    onSave,
    onReport,
    viewMode,
    onAuthRequired,
}) => {
    const [isVotedState, setIsVotedState] = useState(
        isLoggedIn && (votedBy.includes(currentId) || votedBy.includes(currentUsername))
    );
    const [isSavedState, setIsSavedState] = useState(
        isLoggedIn && (savedBy.includes(currentId) || savedBy.includes(currentUsername))
    );
    const [isReportedState, setIsReportedState] = useState(
        isLoggedIn && reportedBy.includes(currentUsername)
    );
    const [localVoteCount, setLocalVoteCount] = useState(voteCount);

    if (viewMode === 'Your') return null;

    const handleVote = async (e) => {
        e.stopPropagation();
        if (!isLoggedIn) {
            onAuthRequired();
            return;
        }
        setIsVotedState(!isVotedState);
        setLocalVoteCount(prevCount => isVotedState ? prevCount - 1 : prevCount + 1);
        try {
            await onVote(contentId);
        } catch (error) {
            setIsVotedState(!isVotedState);
            setLocalVoteCount(prevCount => isVotedState ? prevCount + 1 : prevCount - 1);
        }
    };

    const handleSave = async (e) => {
        e.stopPropagation();
        if (!isLoggedIn) {
            onAuthRequired();
            return;
        }
        setIsSavedState(!isSavedState);
        try {
            await onSave(contentItem);
        } catch (error) {
            setIsSavedState(!isSavedState);
        }
    };

    const handleReport = async (e) => {
        e.stopPropagation();
        if (!isLoggedIn) {
            onAuthRequired();
            return;
        }
        if (!isReportedState) {
            setIsReportedState(true);
            try {
                await onReport(contentId);
            } catch (error) {
                setIsReportedState(false);
            }
        }
    };

    return (
        <div className="ccb-card-buttons">
            <button
                className={`ccb-icon-button ${isVotedState ? 'ccb-voted' : ''}`}
                onClick={handleVote}
                title={isVotedState ? 'Remove Vote' : 'UpVote'}
            >
                <ArrowUpCircle
                    size={isVotedState ? 20 : 18}
                />
                {localVoteCount > 0 && (
                    <span className="ccb-vote-count">{localVoteCount}</span>
                )}
            </button>

            <button
                className={`ccb-icon-button ${isSavedState ? 'ccb-saved' : ''}`}
                onClick={handleSave}
                title={isSavedState ? 'Remove Bookmark' : 'Bookmark'}
            >
                <Bookmark
                    size={18}
                    fill={isSavedState ? 'currentColor' : 'none'}
                />
            </button>

            <button
                className={`ccb-icon-button ${isReportedState ? 'ccb-reported' : ''}`}
                onClick={handleReport}
                disabled={isReportedState}
                title={isReportedState ? 'Reported' : 'Report and Hide'}
            >
                <Flag
                    size={18}
                    fill={isReportedState ? 'currentColor' : 'none'}
                />
            </button>
        </div>
    );
};

export default ContentCardButtons;