import React, { useState } from 'react';
import UserManagement from './UserManagement';
import NotificationsTab from './NotificationsTab';
import ReportedImages from './ReportedImages';
import '../../css/AdminDashboard.css';
import { useDocumentHead } from '../../hooks/useDocumentHead'

const AdminDashboard = () => {
    const [activeTab, setActiveTab] = useState('users');

    useDocumentHead(
        'Admin Dashboard - AI Tools | Devsaura',
        'Access the admin dashboard for managing AI Tools settings and user data. Authorized personnel only.',
        'noindex, nofollow'
    );

    return (
        <div className="ad-admin-dashboard margin">
            <h1 className="ad-dashboard-title">Admin Dashboard</h1>
            <div className="ad-tabs">
                <button
                    className={`ad-tab ${activeTab === 'users' ? 'ad-active' : ''}`}
                    onClick={() => setActiveTab('users')}
                >
                    Users
                </button>
                <button
                    className={`ad-tab ${activeTab === 'notifications' ? 'ad-active' : ''}`}
                    onClick={() => setActiveTab('notifications')}
                >
                    Notifications
                </button>
                <button
                    className={`ad-tab ${activeTab === 'reported-images' ? 'ad-active' : ''}`}
                    onClick={() => setActiveTab('reported-images')}
                >
                    Reported Images
                </button>
            </div>
            <div className="ad-content">
                {activeTab === 'users' && <UserManagement />}
                {activeTab === 'notifications' && <NotificationsTab />}
                {activeTab === 'reported-images' && <ReportedImages />}
            </div>
        </div>
    );
};

export default AdminDashboard;