import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { X, Loader } from 'lucide-react';
import '../../css/UserListPopup.css';

const UserListPopup = ({ 
    isOpen, 
    onClose, 
    users, 
    title, 
    emptyMessage = "No users to display",
    isLoading = false
}) => {
    const menuRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, onClose]);

    if (!isOpen) return null;

    return (
        <div className="user-list-overlay">
            <div ref={menuRef} className="user-list-popup">
                <div className="user-list-header">
                    <h3>{title}</h3>
                    <button 
                        onClick={onClose}
                        className="user-list-close-button"
                    >
                        <X size={20} />
                    </button>
                </div>
                
                <div className="user-list-content">
                    {isLoading ? (
                        <div className="user-list-loading">
                            <Loader size={24} className="animate-spin" />
                            <p>Loading users...</p>
                        </div>
                    ) : users && users.length > 0 ? (
                        <div className="user-list">
                            {users.map((user) => (
                                <Link
                                    key={user.username}
                                    to={`/profile/${user.username}`}
                                    onClick={onClose}
                                    className="user-list-item"
                                >
                                    <img
                                        src={user.profile_image_url || '/default-avatar.png'}
                                        alt={user.username}
                                        className="user-list-avatar"
                                    />
                                    <div className="user-list-info">
                                        <span className="user-list-name">{user.fullName || user.name}</span>
                                        <span className="user-list-username">@{user.username}</span>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    ) : (
                        <p className="user-list-empty">{emptyMessage}</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default UserListPopup;